import React, { Component } from 'react';
import { Box, Button, Stack, Tooltip, Drawer, Typography, TextField, IconButton, Divider, Chip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { CommonDeleteButton, CommonEditButton, DeletePopup, CommonPopUp, CommonGridToolBarWithFilterText } from '../../../Components/Common Components/CommonComponents';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { CheckAccessFunc, splitDate } from '../../../Components/CommonFunctions/CommonFunctions';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import Loader from '../../../Components/Loader';
import { Colors } from '../../../Styles/Colors';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

class PharmaGenericName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      genericNameList: [],
      generic_name: "",
      deletePopup: false,
      deleteId: null,
      dltGeneric_name: "",
      isEdit: false,
      editData: {},
      isNewGeneric: false,
      page: 0,
      pageSize: 10,
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
    }
  }

  componentDidMount() {
    this._getGenericList();
  }

  _getGenericList = () => {
    let { generic_name } = this.state;
    try {
      this.LoaderFunction(true);
      RestAPIService.getAll(`${Serviceurls.PHARMA_GENERIC_NAME_LIST}?search_key=${generic_name ? generic_name : ""}`)
        .then(response => {
          if (response.data.status === "success") {
            this.LoaderFunction(false);
            let list = response.data?.data?.length > 0 ? response.data.data : [];
            list.length > 0 && list?.forEach((item, i) => {
              item["s.no"] = i + 1;
            })

            this.setState({
              genericNameList: list
            });
          }
        })
        .catch(error => {
          if (error.response.data.status === "Failed") {
            this.LoaderFunction(false);
            this.errorMessage(error.response?.data?.message);
          }
        })
    } catch (e) {
      this.errorMessage(e.message);
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  handleEdit = (data) => {
    this.setState({
      isEdit: true,
      editData: data,
      generic_name: data.generic_name,
    })
  }

  handleDelete = (data) => {
    if (!this.state.isEdit) {
      this.setState({
        deletePopup: true,
        deleteId: data.id,
        dltGeneric_name: data.generic_name,
      })
    }
  }

  updatePopUpClose = () => {
    this.setState({
      isNewGeneric: false,
      isEdit: false,
      editData: {},
      generic_name: ""
    })
  }

  check_Mandatory_Fields = () => {
    let states = this.state;
    if (states.generic_name !== "") {
      return true;
    }
    return false;
  }

  postGeneric = () => {
    let states = this.state;
    let isvalid = this.check_Mandatory_Fields();
    if (isvalid) {
      let datas = {
        generic_name: states.generic_name ? states.generic_name : "",
        id: states.editData ? states.editData.id : ""
      }
      try {
        this.LoaderFunction(true);
        RestAPIService.create(datas, Serviceurls.PHARMA_GENERIC_NAME_LIST)
          .then(response => {
            if (response.data.status === "success") {
              this.LoaderFunction(false);
              this.successMessage(response.data.message);
              this.updatePopUpClose();
              this._getGenericList();
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.LoaderFunction(false);
              this.errorMessage(error.response.data.message);
            }
          });
      } catch (e) {
        this.errorMessage(e.message);
      }
    }
  }

  updateGeneric = () => {
    const { t } = this.props;
    let states = this.state;

    const filtereddata = states.generic_name && states.generic_name.length > 1 ? states.genericNameList.filter((data) => (
      (data.generic_name === null ? null : data.generic_name.toLowerCase().includes(states.generic_name.toLowerCase()))
    )) : [];

    return (
      <Stack direction="column" alignItems="flex-start" className="eMed_generic_editDiv">
        {this.renderTextField()}
        <Typography fontWeight={600} id="eMed_PharmaVendor_popNorTxt">{t("GenericAlreadyExist")}</Typography>
        {filtereddata.length > 0 ?
          <>
            <Stack id="eMed_generic_chipDiv" direction="row" justifyContent="flex-start" alignItems="center" spacing={1} flexWrap="wrap">
              {filtereddata.map((item, i) => {
                if (item.generic_name !== "") {
                  return (<Chip key={i} id="eMed_generic_chip"
                    label={item.generic_name}
                    sx={{ '& .MuiChip-label': { fontSize: "0.8vw" } }}
                    color={'primary'} variant={"outlined"} />)
                }
              })}
            </Stack></>
          :
          <Stack id="eMed_generic_chipDiv" direction="row" justifyContent='center' alignItems="center" spacing={1} flexWrap="wrap">
            <Typography color={Colors?.disableLabelColor}>{states.generic_name === "" ? "Enter Generic Name" : "No Matches Found"}</Typography>
          </Stack>}
      </Stack>
    )
  }

  PopupClose = () => {
    this.setState({
      deletePopup: false,
      deleteId: null,
      dltGeneric_name: "",
    })
  }

  removeDataList = () => {
    if (this.state.deleteId) {
      try {
        this.LoaderFunction(true);
        this.setState({disableBtn: true})
        RestAPIService.delete(`${Serviceurls.PHARMA_GENERIC_NAME_LIST}?id= ${this.state.deleteId}`)
          .then(response => {
            if (response.data.status === "success") {
              this.LoaderFunction(false);
              this.PopupClose();
              this.successMessage(response.data.message, true);
              this._getGenericList();
              this.setState({disableBtn: false})
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.LoaderFunction(false);
              this.errorMessage(error.response.data.message, false);
              this.setState({disableBtn: false})
              if (error.response.data?.message === "Generic Name is Currently In Use, Cant Delete Right Now") {
                this.PopupClose();
                this._getGenericList();
                this.setState({disableBtn: false})
              }
            }
          });
      } catch (e) {
        this.errorMessage(e.message, false);
        this.setState({disableBtn: false})
      }
    }
  }

  // filter start
  closeFilter = () => {
    this.setState({ FilterOpen: false, generic_name: "" })
  }

  renderTextField = (isEdit) => {
    const { t } = this.props;
    let states = this.state;
    return (
      <Box className={isEdit ? 'eMed_generic_dropdown' : "eMed_rts_dropdown"}>
        <TextField
          label={t("GenericName")}
          sx={{ width: "20vw" }}
          size="small"
          autoComplete="off"
          value={states.generic_name ? states.generic_name : ""}
          onChange={(e) => {
            this.setState({
              generic_name: e.target.value
            })
          }}
          inputProps={{ emed_tid: "generic_text" }}
        />
      </Box>
    )
  }

  renderFilterScreen = () => {
    const { t } = this.props;
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
          <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          {this.renderTextField()}
        </Box>
        <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
          <Button emed_tid="clear_generic_filter" variant='outlined' size="small"
            onClick={() => {
              this.setState({
                generic_name: "",
              }, () => this._getGenericList())
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button emed_tid="clear_generic_search" variant='contained' size="small"
            onClick={() => {
              this._getGenericList()
              this.setState({ FilterOpen: false })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }
  // filter end

  onFilterChange = (filterModel) => {
    if(filterModel?.quickFilterValues[0]?.length >= 2 || filterModel?.quickFilterValues?.length === 0 ){
      this.setState({
        generic_name: filterModel?.quickFilterValues[0] ? filterModel?.quickFilterValues[0] : ""
      }, () =>  {
        this._getGenericList();
      })
    }
  }

  gridToolBar = () => {
    const {t} = this.props;
    return (
      <Box>
        <CommonGridToolBarWithFilterText  searchHolder = {t("searchGenericName")}/>
      </Box>
    )
  }

  render() {
    const { t } = this.props;
    let states = this.state;
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    let pharmacy_type = UserData?.pharmacy_type === 'Inhouse Pharmacy' ? "Pharmacy" : "Standalone Pharmacy";
    const columns = [
      { field: 's.no', headerName: t('SNo'), flex: 0.05, sortable: false, hideable: false },
      {
        field: 'generic_name', headerName: t('GenericName'), flex: 0.18, hideable: false, renderCell: ({ row }) => (
          row?.generic_name?.length > 20 ? <Tooltip placement="top" title={row?.generic_name} arrow><div>{row?.generic_name.slice(0, 18) + "..."}</div></Tooltip>
            : <div>{row.generic_name ? row.generic_name : "-"}</div>
        )
      },
      {
        field: 'created_by', headerName: t('CreatedBy'), flex: 0.17, hideable: false, renderCell: ({ row }) => (
          row?.created_by?.length > 20 ? <Tooltip placement="top" title={row?.created_by} arrow><div>{row?.created_by.slice(0, 18) + "..."}</div></Tooltip>
            : <div>{row.created_by ? row.created_by : "-"}</div>
        )
      },
      {
        field: 'created_date', headerAlign: 'center', headerName: t('CreatedDate'), flex: 0.16, hideable: false, align: "center", renderCell: ({ row }) => (
          <div>{row.created_date ? splitDate(row.created_date) : "-"}</div>)
      },
      {
        field: 'modified_date', headerAlign: 'center', headerName: t('LastModifiedDate'), flex: 0.16, hideable: false, align: "center", renderCell: ({ row }) => (
          <div>{row.modified_date ? splitDate(row.modified_date) : "-"}</div>
        )
      },
      {
        field: 'modified_by', headerName: t('LastModifiedBy'), flex: 0.18, hideable: false, renderCell: ({ row }) => (
          row?.modified_by?.length > 20 ? <Tooltip placement="top" title={row?.modified_by} arrow><div>{row?.modified_by.slice(0, 18) + "..."}</div></Tooltip>
            : <div>{row.modified_by ? row.modified_by : "-"}</div>
        )
      },
      {
        field: 'actions', headerName: t('Action'), type: 'actions', flex: 0.10, sortable: false, hideable: false,
        renderCell: ({ row }) => (
          <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={1}>
            <CommonEditButton disable={!CheckAccessFunc(pharmacy_type, "Settings", "Generic Name",  null, "Tab")?.editAccess} testID={"generic_edit"} size="1.5vw" onClick={this.handleEdit.bind(this, row)} />
            <CommonDeleteButton disable={!CheckAccessFunc(pharmacy_type, "Settings", "Generic Name",  null, "Tab")?.editAccess} testID={"generic_delete"} size="1.5vw" onClick={this.handleDelete.bind(this, row)} />
          </Stack>)
      }
    ]

    return (
      <Box component={'div'} className='eMed_rts_container'>
        <div className='eMed_Purchase_Table_top'>
          <Button emed_tid="add_generic" disabled={!CheckAccessFunc(pharmacy_type, "Settings", "Generic Name",  null, "Tab")?.editAccess} className='eMed_Filter_Btns' variant='contained' size='small' onClick={() => this.setState({ isNewGeneric: true })}>{t(`AddNewGenericName`)}</Button>
          <Divider orientation='vertical' />
          {/* <Tooltip title="Filter" placement='top' arrow>
            <IconButton emed_tid="add_filter" className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
              <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
            </IconButton>
          </Tooltip> */}
        </div>
        <Box component={'div'} className='eMed_rts_table'>
          <DataGrid
            rows={states.genericNameList}
            columns={columns}
            getRowId={(row) => row.id}
            page={states.page}
            pageSize={states.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            filterMode="server"
            onFilterModelChange={this.onFilterChange}
          />
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {
          this.state.isNewGeneric || this.state.isEdit && this.state.editData ?
            <CommonPopUp width={"50vw"}
              popUpClose={this.updatePopUpClose.bind(this)}
              title={this.state.isEdit ? t("EditGenericName") : t("AddGenericName")}
              component={this.updateGeneric.bind(this)}
              justifyRight={true}
              buttonOne={t("Cancel")}
              buttonOneAction={this.updatePopUpClose.bind(this)}
              buttonTwo={this.state.isEdit ? t("Update") : t("Add")}
              buttonTwoAction={this.postGeneric.bind(this)}
              disableBtn2={!this.check_Mandatory_Fields()}
              testID="generic"
            />
            : null
        }
        {this.state.deletePopup ?
          <DeletePopup DeletTitle={`${t("deleteMsg")}  ${states.dltGeneric_name ? states.dltGeneric_name : "-"} ?`}
            deleteAlertPopupClose={this.PopupClose.bind(this)}
            removeData={this.removeDataList.bind(this)}
            testID="generic_delete_ok"
            disable={this.state.disableBtn}
          />
          : null}
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
export default withTranslation()(PharmaGenericName);