import React, { Component } from 'react';
import {
  Box, Button, IconButton, MenuItem, Paper, Stack, Tooltip, Menu, Typography
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next'
import '../../FrontOffice/BillingTransaction/Billings.css';
import Drawer from '@mui/material/Drawer';
import { DateTime } from 'luxon';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { ImagePaths } from '../../../Utility/ImagePaths';
import { AmountFormat, formatDate, CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonCorporateAndInsuranceDetails, CommonGridToolBarWithFilterTextCustom } from '../../../Components/Common Components/CommonComponents';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import InvoiceFilter from './InvoiceFilter';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';
import CreditTypeChangePop from '../../FrontOffice/BillingTransaction/CreditTypeChangePop';
import { CurrencySymbol } from '../../../Utility/Constants';

let RoleData = null;
let userAccess = null;
class PharmaCreditBills extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      pageSize: 10,
      BillList: [],
      ref: props.location?.pathname,
      FilterOpen: false,
      FilterDatas: {
        "FromDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "ToDate": DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
        "DateIndex": 1,
        "PatientName": "",
        "AccNo": "",
        "MobNo": "",
        "DoctorName": [],
        "BillNumber": "",
        "PaidAmount": "",
        "InPatientSelected": true,
        "OutPatientSelected": true,
        "PharmacySelected": true,
        "LaboratorySelected": true,
        "ImageLabSelected": true,
        "OTSelected": true,
        "CardSelected": true,
        "CashSelected": true,
        "UPISelected": true,
        "BankTransferSelected": true,
        "InsuranceSelected": true,
        "ChequeSelected": true,
        "BillType": ['op', 'ip', 'pharmacy'],
        "PayStatus": [],
        "PayMethods": [],
        'BillStatus': ["completed", "saved", "credit", "cancelled"],
        "CompletedSelected": true,
        "CreditSelected": true,
        "SavedSelected": true,
        "CancelledSelected": true,
        "CreditStatus": ['Corprate credit', 'Insurance credit', 'Patient credit'],
        "InsuranceCredit": true,
        "CorporateCredit": true,
        "PatientCredit": true,
        "is_bill_wise" : false,
        "patient_Name": "",
        "phoneNumber": "",
        "UHID_No": "",
        "Bill_No": "",
        "IncludePaidBills": false,

      },
      AmountDetails: {
        "TotalAmount": 0,
        "TotalPaidAmount": 0,
        "TotalReturnAMount": 0,
        "TotalPendingAmount": 0,
      },
      SelectedBill: {},
      AppointmentDetails: {},
      CreditBillPopUp: false,
      SelectedBill: {},
    }
  }

  componentDidMount() {
    RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"));
    userAccess = CheckAccessFunc("Pharmacy", "Billing Transaction", 'Credit Bills', null, "Tab");
    this.GetBillList()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  GetBillList() {
    try {
      var states = this.state
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.PHARMACY_CREDIT_BILL_LIST +
        "?from_date=" + states.FilterDatas.FromDate +
        "&to_date=" + states.FilterDatas.ToDate+
        "&credit_type=" + states.FilterDatas.CreditStatus +
        "&is_bill_wise=" + states.FilterDatas?.is_bill_wise + 
        "&bill_type=" + states.FilterDatas.BillType + 
        "&patient_name=" + states.FilterDatas.patient_Name +
        "&uhid=" + states.FilterDatas.UHID_No + 
        "&mob_no=" + states.FilterDatas.phoneNumber +
        "&bill_number=" + states.FilterDatas.Bill_No +
        "&include_paid_bill=" + states.FilterDatas.IncludePaidBills 
      ).
        then((response) => {
          if (response.data.status === "success") {
            states.AmountDetails.TotalAmount = response?.data?.total_bill_amount;
            states.AmountDetails.TotalPendingAmount = response?.data?.total_outstanding_amount;
            states.AmountDetails.TotalPaidAmount = response?.data?.total_paid_amount;
            states.AmountDetails.TotalReturnAMount = response?.data?.total_return_amount;
            this.setState({
              BillList: response.data.data,
              AmountDetails: states.AmountDetails,
            }, () => { this.LoaderFunction(false) })
          }
          else {
            this.LoaderFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.LoaderFunction(false)
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  printCreditBills = () => {
    let states = this.state

    try {
      this.setState({disableBtn: true})
      RestAPIService.getAll(Serviceurls.PHARMACY_CREDIT_BILL_LIST +
        "?from_date=" + states.FilterDatas.FromDate +
        "&to_date=" + states.FilterDatas.ToDate+
        "&credit_type=" + states.FilterDatas.CreditStatus +
        "&is_bill_wise=" + states.FilterDatas?.is_bill_wise + 
        "&bill_type=" + states.FilterDatas.BillType + 
        "&patient_name=" + states.FilterDatas.patient_Name +
        "&uhid=" + states.FilterDatas.UHID_No + 
        "&mob_no=" + states.FilterDatas.phoneNumber +
        "&bill_number=" + states.FilterDatas.Bill_No +
        "&include_paid_bill=" + states.FilterDatas.IncludePaidBills +
        "&export_type=" + "pdf"   
      )
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({disableBtn: false})
        }).catch((error) => {
          if (error.response?.data?.message) {
            this.errorMessage(error.response?.data?.message);
          } else {
            this.errorMessage(error.message);
          }
          this.setState({disableBtn: false})
        })
    } catch (error) {
      this.errorMessage(error.message)
      this.setState({disableBtn: false})
    }
  }

  renderFilter = (data) => {
    this.setState({
      FilterDatas: data,
      FilterOpen: false,
    }, () => { this.GetBillList() })
  }

  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "ChangeCreditType", label: t("Change Credit Type") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            disabled={this.state.CreditTypeChangePopup}
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (
              <MenuItem key={option} onClick={() => {
                this.setState({ anchorEl: null }, () => {
                  if (option.value === "ChangeCreditType") {
                    data.row.pending_amount = data.row.outstanding_amount
                    data.row.total_amount = data?.row?.bill_amount
                    data.row.mobile_number = data?.row?.billing_customer_mobile_no
                    data.row.patient_name = data?.row?.billing_customer_name
                    data.row.uhid = data?.row?.patient_account_number
                    data.row.patient_age = ""
                    data.row.bill_types = data?.row?.module === "OP" ? "Out Patient" : data?.row?.module === "IP"  ? "In Patient" : "Pharmacy"
                    this.setState({ CreditTypeChangePopup: true, SelectedBill: data?.row })
                  }
                })
              }}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }


  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  ClosePopUp(Getapicall = false, message = "") {
    this.setState({
      FilterOpen: false,
      BillPreviewPop: false,
      CreditBillPopUp: false,
      CreditTypeChangePopup: false
    }, () => {
      if (Getapicall && message !== "") {
        this.successMessage(message)
        this.GetBillList()
      }
    })
  }

  CreditTypechange(message) {
    this.setState({
      CreditTypeChangePopup: false,
    }, () => {
      this.successMessage(message)
      this.GetBillList()
    })
  }

  gridToolBar = () => {
    var { t } = this.props
    let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
    let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
    let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);

    let TextArray = [
      { label: "From Date", value: formatDate(this.state.FilterDatas.FromDate) },
      { label: "To Date", value: formatDate(this.state.FilterDatas.ToDate) },
    ]
    var ExportData = []
    console.log(this.state.BillList)
    this.state.BillList?.length > 0 && this.state.BillList.map((item) => 
    ExportData.push({"SNo":item.sno,"PatientName":item.billing_customer_name,"MobileNumber":item.billing_customer_mobile_no,"Module":item.module,"Credit Type":item.credit_type,"Total Bills":item.total_bill,"Bill Amount ":item.bill_amount,"Paid Amount":item.paid_amount,"Outstanding Amount":item.outstanding_amount})
    )

    return (
      <Box>
        <CommonGridToolBarWithFilterTextCustom FilterTextArray={TextArray}   data={ExportData} title={"Pharmacy CreditBills"} filename={"Credit Bills"}/>
      </Box>

    )
  }

  ClearFilterData = (data) => {
    this.setState({
      FilterDatas: data,
      FilterOpen: false
    }, () => { this.GetBillList() })
  }


  render() {
    this.state.BillList.forEach((element, index) => { element.sno = index + 1 })
    const { t } = this.props
    const columns = [
      {
        field: "sno", flex: 0.03, headerName: t("SNo"), headerAlign: "center", align: "center", cellClassName: 'cre_sno',
        renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
      },
      {
        field: "billing_customer_name", flex: 0.16, headerName: t("PatientName"), headerAlign: "left", align: "left",
        renderCell: (params) => {
          let whatGender = ((params.row?.patient_gender === 'Male' || params.row?.patient_gender === 'm' || params.row?.gender === "m" || params.row?.gender === "M" || params.row?.patient__gender === "m") ? "M" : (params.row?.patient_gender === 'Female' || params.row?.patient_gender === 'f' || params.row?.gender === "f" || params.row?.gender === "F" || params.row?.patient__gender === "f") ? "F" : (params.row?.patient_gender === "Trans" || params.row?.patient_gender === 't' || params.row?.gender === "t" || params.row?.gender === "T" || params.row?.patient__gender === "t") ? "T" : "-");
          let PatientAccNumber = params.row?.patient_account_numbers ? params.row?.patient_account_numbers : params.row?.patient_account_number ? params.row?.patient_account_number : params.row?.uhid ? params.row?.uhid : params.row?.patient_acc_no ? params.row?.patient_acc_no : params.row?.patient_uhid ? params.row?.patient_uhid : "-";
          let mobile_number = params.row?.billing_customer_mobile_no;
          return (
            <Box component={'div'}>
              <Typography component={'div'}>
                {(params.row.billing_customer_name?.length) > 20 ?
                  <Tooltip title={params.row.billing_customer_name} placement={'top'} arrow>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Typography id='eMed_Com_Patient_Name' style={{ fontSize: "0.8vw" }}>{(params.row.billing_customer_name ? ((params.row.billing_customer_name?.slice(0, 20) + '...')) : '-') + ' ' + (params.row.patient_age ? params.row.patient_age : '-') + '/' + (params.row.patient_gender ? whatGender : '-')}</Typography>
                    </div>
                  </Tooltip> :
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography id='eMed_Com_Patient_Name' style={{ fontSize: "0.8vw" }}>{(params.row.billing_customer_name ? params.row.billing_customer_name : '-') + ' ' + (params.row.patient_age ? params.row.patient_age : '-') + '/' + (params.row.patient_gender ? whatGender : '-')}</Typography>
                  </div>
                }
              </Typography>
              <Typography id='eMed_Com_Patient_UhidText'>{(PatientAccNumber) + ' | ' + (mobile_number ? mobile_number : '-')}</Typography>
            </Box>)
        }
      },
      {
        field: "module", flex: 0.10, headerName: t("Module"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.module ? params?.row?.module : "-"}</Box>)
      },
      {
        field: "credit_type", flex: 0.14, headerName: t("Credit Type"),
        renderCell: (params) => (<Box component={'div'}>{params?.row?.credit_type ? <CommonCorporateAndInsuranceDetails data={params?.row} /> : "-"}</Box>)
      },
      {
        field: "module_number", flex: 0.10, headerName: t("Bill No"), headerAlign: "center", align: "center",
        renderCell: (params) => {
          let BillNumber = params?.row?.module_number ? (params?.row?.module_number) : "-";
          const billArr = BillNumber ? BillNumber.split("/") : [];
          return (
              <Tooltip placement='top' title={BillNumber} arrow>
                  <Box component={'div'}>{billArr?.length > 0 ? '...' + billArr.slice(-2).join("/") : "-"}</Box>
              </Tooltip>
          )
     
        }
      },
      {
        field: "total_bill", flex: 0.08, headerName: t("Total Bills"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.total_bill ? params?.row?.total_bill : "-"}</Box>)
      },
      {
        field: "bill_amount", flex: 0.10, headerName: t(`Bill Amount (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.bill_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      {
        field: "paid_amount", flex: 0.10, headerName: t(`Paid Amount (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.paid_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      {
        field: "return_amount", flex: 0.10, headerName: t(`Return Amount (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.return_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
      {
        field: "outstanding_amount", flex: 0.08, headerName: t(`Outstanding Amount (${CurrencySymbol})`), type: "number",
        renderCell: (params) => (<Box component={'div'}>{AmountFormat(params?.row?.outstanding_amount || 0)?.replace(`${CurrencySymbol}`, "")}</Box>)
      },
    ]

    if (userAccess?.editAccess) {
      columns.push(
        {
          field: "amount", flex: 0.10, headerName: t("Action"), headerAlign: "center", align: "center",
          renderCell: (params) => (<Box component={'div'} display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Tooltip title={t("View Bills")} placement="top" arrow>
              <Button emed_tid="view_bills" onClick={() => {
                this.props.history.push({ pathname: "/PharmacyInvoice/CreditBills/Bills", state: { SelectedCreditBill: params.row, FilterDatas :this.state.FilterDatas } })
              }} className='eMed_usrconf_btn'>
                <img src={ImagePaths.LabViewButton.default} alt="print" className='eMed_action_img' />
              </Button>
            </Tooltip>
            {this.MenuItem(params)}
          </Box>)
        },
      );
    }

    return (
      <Box component={"div"} className='eMed_Main_container'>
        <Box component={"div"} className='eMed_Top_Container'>
          <Box component={"div"} className='eMed_Cards_Container'>
            {AmountsCard(t("Total Bill Amount"), this.state.AmountDetails?.TotalAmount, false, "white", false, null, 'cre_total_amt')}
            {AmountsCard(t("Total Paid Amount"), this.state.AmountDetails?.TotalPaidAmount, false, "white", false, null, 'cre_paid_amt')}
            {AmountsCard(t("Total Return Amount"), this.state.AmountDetails?.TotalReturnAMount, false, "white", false, null, 'cre_return_amt')}
            {AmountsCard(t("Total Pending Amount"), this.state.AmountDetails?.TotalPendingAmount, false, "white", false, null, 'cre_pend_amt')}
          </Box>
          <Box component={"div"} className='eMed_Action_Container'>
            <Box component={"div"} className='eMed_Filter_Icon_div'>
              <Tooltip title="Filter" placement='top' arrow>
                <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                  <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                </Button>
              </Tooltip>
            </Box>
            <Tooltip title="Print" placement='top' arrow>
              <Button className='eMed_usrconf_btn' emed_tid='Pharmacy_CreditBill_Print_Btn' disabled={this.state.disableBtn} onClick={() => this.printCreditBills()}>
                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
              </Button></Tooltip>
          </Box>
        </Box>
        <Box component={"div"} className='eMed_Table_Container'>
          <Stack component={"div"}>
            <Paper className='eMed_Table_Wrapper' >
              <DataGrid
                rows={this.state.BillList}
                classes={{ main: 'pharma_credit_bill' }}
                columns={columns}
                getRowId={(row) => row['sno']}
                page={this.state.page}
                pageSize={this.state.pageSize}
                hideFooterSelectedRowCount
                localeText={{
                  toolbarColumns: "",
                  toolbarDensity: "",
                  toolbarExport: "",
                  toolbarFilters: "",
                  toolbarExportPrint: ""
                }}
                headerHeight={40}
                components={{
                  Toolbar: this.gridToolBar,
                  NoRowsOverlay: () => (
                    <Stack className='eMed_conf_nodata'>
                      {t("NoRecordsFound")}
                    </Stack>
                  )
                }}
                rowsPerPageOptions={[10, 20, 30]}
                disableSelectionOnClick
                pagination
                onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                loading={this.state.isLoader}
              />
            </Paper>
          </Stack>
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.ClosePopUp.bind(this) }}
        >
          <InvoiceFilter PageName={"CreditBills"} FilterDatas={this.state.FilterDatas} CloseFilter={this.ClosePopUp.bind(this)} FilteredData={this.renderFilter.bind(this)} ClearFilterData={this.ClearFilterData.bind(this)} />
        </Drawer>

        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {
          this.state.CreditTypeChangePopup ? <CreditTypeChangePop BillData={this.state.SelectedBill} CrebillTypeChaged={this.CreditTypechange.bind(this)} ClosePopUp={this.ClosePopUp.bind(this)} /> : null
        }
      </Box>
    )
  }
}

export default withTranslation()(PharmaCreditBills)

