import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import QuickBilling from '../QuickBillAppointments/QuickBilling'
import { Box } from '@mui/material'
import { CommonPatientCard, CommonPatientDetails } from '../../../../Components/Common Components/CommonComponents'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import CommonOpBilling from '../QuickBillAppointments/CommonOpBilling'

class OpBilling extends Component {
    constructor(props) {
        super(props)
        this.state = {
            opPatientBilling: true,
            patientData: {},
            opBillPatientData:[],
            attender_name:"",
            attender_mobile:"",
            invoice_id:null,
            BillData:{},
            forCancel: false,
            isForEditBill: false,
            // fromOp:this.props.history?.location?.state?.AppointmentID ? this.props.history?.location?.state?.AppointmentID : true
        }
    }

    componentDidMount() {
        let CancelBilldatafromLocal = getCachevalue("OPCancelBilldata") ? getCachevalue("OPCancelBilldata") : "{}";
        let CancelBillData = JSON.parse(CancelBilldatafromLocal)
        if (this.props.history?.location?.state?.AppointmentID !== this.state.patientData?.appointment_id && this.props.history?.location?.state?.AppointmentID !== "") {
            let opPatientData = getCachevalue("patientData")
            let patientData = JSON.parse(opPatientData)
            var forcancel = this.props.history?.location?.state?.ForCancel ? true : false
            this.setState({
                patientData: patientData,
                forCancel : forcancel,
                invoice_id : this.props.history?.location?.state?.InvoiceID,
                isForEditBill : this.props.history?.location?.state?.isForEditBill ? true : false 
            },()=>{ 
                this.getBillingPatientData(this.props.history?.location?.state?.AppointmentID)
                this.props.history.push({ state: { AppointmentID: "", InvoiceID:"" , isCancelBillFetched : (this.state.forCancel || this.state.isForEditBill), FromBills : this.props.history?.location?.state?.FromBills } })
            }) 
          }
          else if(CancelBillData?.AppointmentID !== this.state.patientData?.appointment_id && CancelBillData?.AppointmentID !== ""){
            let opPatientData = getCachevalue("patientData")
            let patientData = JSON.parse(opPatientData)
            this.setState({
                patientData: patientData,
                forCancel : true,
                invoice_id : CancelBillData?.InvoiceID,
                isForEditBill : CancelBillData?.isForEditBill ? true : false
            },()=>{ 
                this.getBillingPatientData(CancelBillData.AppointmentID)
                this.props.history.push({ state: { AppointmentID: "", InvoiceID:"" , isCancelBillFetched : true, FromBills : CancelBillData?.FromBills } })
            }) 
          }
          else{
            let opPatientData = getCachevalue("patientData")
            let patientData = JSON.parse(opPatientData)
            let appointmentDetail = getCachevalue("withoutAppointment");
            let details = JSON.parse(appointmentDetail);
            if (details?.patient_id === patientData?.patient_id) {
                this.setState({
                    withOutAppointment: details
                }, () => this.getBillingPatientData())
            }
            this.setState({
                patientData: patientData
            },()=>{
                this.getBillingPatientData()
                this.props.history.push({ state: { AppointmentID: "", InvoiceID:"" , isCancelBillFetched : false } })
            })
          }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    getBillDatas=()=>{
        try{
            let Invoice = this.state.invoice_id ? this.state.invoice_id : ''
            this.setState({ isBillServicesLoading : true})
            RestAPIService.getAll(Serviceurls.FO_PATIEMT_BILLING + "?invoice_id=" + Invoice).
            then((response) => {
                if (response.data.status === "success") {
                    this.setState({
                        BillData: response.data.data,
                        isBillServicesLoading : false
                    })
                }
                else{
                    this.setState({ isBillServicesLoading : false})
                    this.errorMessage(response.data.message)
                }
            }).catch((error) => {
                this.setState({ isBillServicesLoading : false})
                this.errorMessage(error.message)
            })
        }
        catch(e){
            this.setState({ isBillServicesLoading : false})
            this.errorMessage(e.message)
        }
    }
    getBillingPatientData = (AppointID = null) => {
        let appointment_id = AppointID ? AppointID : (this.state.patientData?.appointment_id || this.state.withOutAppointment?.appointment_id);
        try {
            RestAPIService.getAll(Serviceurls.FO_PATIENT_APPOINTMENT_DETAILS + `?appointment_id=${appointment_id}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            opBillPatientData: response.data.data,
                            attender_name: response.data.data.caretaker_name,
                            attender_mobile: response.data.data.caretaker_number,
                        },()=>{
                            if(AppointID !== null && this.state.invoice_id){
                                // this.setState({patientData:response.data.patient_data[0]},()=> {

                                    this.getBillDatas()
                                // })
                            }
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: ''
        })
      }
      OverallBillNavigate = (status) => {
        var { history } = this.props;
        if(status == true){
            this.setState({ BillData : {}}, ()=>{
                if(this.props.history?.location?.state?.FromBills){
                    history.push({ state: { AppointmentID: "", InvoiceID:"" , SuccessFromCancel : true } })
                }else{
                    history.push({pathname: '/BillingTransaction/OverAllBills'})
                }
            })
        }
        
      }
      ClearBillDatas = () =>{
        this.setState({
            BillData : {}
        })
      }
    render() {
        const {t} = this.props
        let data = {
            lineOne: t("Emergency Contact Person"),
            lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
            lineFour: t('Emergency Mobile No'),
            LineFive:`${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
        }
        return (
            <Box component={'div'} sx={{backgroundColor:"#F5F8F7"}} height={'74vh'} >
                <Box component={'div'} height={"9.5vh"}>
                    <Box component={'div'} display={'flex'}>
                        <Box component={'div'} className='eMed_nur_notes_details' ml={'0.8vw'}>
                            <CommonPatientDetails data={this.state.patientData} />
                        </Box>
                        <CommonPatientCard details={data} showDetailed={true}/>
                    </Box>
                </Box>
                <CommonOpBilling 
                BillData={this.state.BillData} 
                forCancel={this.props.history?.location?.state?.ForCancel ? true : this.state.forCancel}
                isForEditBill={this.props.history?.location?.state?.isForEditBill ? true : this.state.isForEditBill}
                opPatientBilling={this.state.opPatientBilling}
                 appointmentId={this.state.patientData?.appointment_id}
                 opPatientSavedBill={this.state.opPatientSavedBill}
                 OverallBillNavigate={this.OverallBillNavigate.bind(this)}
                 ClearBillDatas={this.ClearBillDatas.bind(this)}
                 SuccessFromCancel={this.props.history?.location?.state?.SuccessFromCancel ? true : false}
                 isBillServicesLoading={this.state.isBillServicesLoading}
                 opBillPatientData={this.state.opBillPatientData}
                 />
                {/* <QuickBilling  opPatientBilling={this.state.opPatientBilling} appointmentId={this.state.patientData?.appointment_id} /> */}
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}


export default withTranslation()(OpBilling)