import React, { Component } from 'react'
import './IpPatientDetails.css'
import {
  Box, FormControl, InputLabel, Select, MenuItem, Stack, Button,
  TextField, Tooltip, TableCell, IconButton, TableBody, TableRow,
  Autocomplete, TableContainer, TableHead, Table, InputAdornment,
  Modal,
} from '@mui/material';
import { CommonPatientDetails, CommonPatientCard, ConsultantPatientCard, ToolsFieldWithMic } from '../../../../Components/Common Components/CommonComponents';
import { getCachevalue, localGetItem } from '../../../../Utility/Services/CacheProviderService';
import { withTranslation } from 'react-i18next';
import { Paper, Typography } from '@mui/material';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Colors } from '../../../../Styles/Colors';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { DateTime } from 'luxon';
import Loader from '../../../../Components/Loader';
import { ConfirmPopup } from '../../../../Components/Common Components/CommonComponents';
 import  VoiceToText from '../../../../Components/Common Components/VoiceToText';
import  MicIcon from '@mui/icons-material/Mic';
import {  grey } from '@mui/material/colors';
import { CheckAccessFunc, formatDate, formatDateAndTimeMeth2, formatDateAndTime } from '../../../../Components/CommonFunctions/CommonFunctions';
import { IP_routeList, DischargeTypeList } from '../../../../Utility/Constants';
import { TimePicker } from '@mui/x-date-pickers';
import CopyDisSumPop from './CopyDisSumPop';
import { ContentCopy } from '@mui/icons-material';

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '15vw',
    },
  },
};
const radPrintEnable = new Set(["Result Saved", "Completed", "Dispatched"])
class DischargeSummary extends Component {
  constructor(props) {
    super(props)
    let LoggedData = localGetItem("loggedInUserInfo") ? JSON.parse(localGetItem("loggedInUserInfo")) : null
    let PharmacyList = LoggedData?.pharmacy_data ? JSON.parse(JSON.stringify(LoggedData?.pharmacy_data)) : []
    this.state = {
      ipPatientData: {},
      selectLayout: '',
      selectTemplate: '',
      disDateTimePicker: null,
      DischargeTime: null,
      nextDatePicker: null,
      codeICDapi: [],
      icdCode: '',
      selecticd: null,
      disLayoutDesign: [],
      AlldisLayoutDesign: [],
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      icdADTblData: [],
      icdDDTblData: [],
      mediRoute: '',
      mediDosage: '',
      mediName: '',
      selectMEAN: '',
      selectConsumptions: '',
      mediInstruction: '',
      medicationTbl: [],
      inHospmedicationTbl: [],
      inHospmediRoute: '',
      inHospmediName: '',
      inHospmediDosage: '',
      inHospselectMEAN: '',
      inHospselectConsumptions: '',
      inHospmediInstruction: '',
      inHospselectedIndex: null,
      selectDiet: '',
      selectActivity: '',
      selectDischargeProvisional: "",
      selectAdmissionProvisional: "",
      adviceDischarge: '',
      typeAnaesthesia: '',
      nameAnaesthesia: '',
      disSumTemData: [],
      selectedIndex: null,
      postDisSumData: {},
      disSumdata: {},
      reasonAdmission: '',
      getDisSumData: [],
      selectSurDate: null,
      disSumID: null,
      isFinish: false,
      isLoader: false,
      alertPop: false,
      clearPop: false,
      temLayId: null,
      completePop: false,
      notifyPop: false,
      temraryID: null,
      withOutAppointment: {},
      specTxt: '',
      selectSpeciality: null,
      doctor_name: null,
      splItem: [],
      AllsplItem: [],
      testData: [],
      invetigation: '',
      testgetData: [],
      attenderName: '',
      attenderMobile: '',
      consultantName: null,
      catagoryList: [],
      selectedCategory: '',
      categoryType:'',
      listenPopUp:false,
      ClickKey:true,
      FilteredTestData : [],
      brandnameList : [],
      AllDoctorsList:[],
      FilteredDoctorList:[],
      OpenPrevCopyPop : false,
      DischargeType : "Normal",
      isSave: false,
      PharmacyList : PharmacyList,
      selectedPharmacy : PharmacyList?.length ? PharmacyList[0]?.pharmacy_id : null,
      vitalsTblData: [],
      radTblData: [],
      isRadPrint: false,
    }
  }
  componentDidMount() {
    let IpPatientDetails = this.props?.isFromIPDoctor ? getCachevalue('IppatientData') : this.props?.isFromDoctor ? getCachevalue('DoctorPatientData') : getCachevalue('IppatientData')
    let patientData = JSON.parse(IpPatientDetails)
    let appointmentDetail = getCachevalue("withoutAppointment");
    let details = JSON.parse(appointmentDetail);
    if (details?.patient_id === patientData?.patient_id) {
      this.setState({
        withOutAppointment: details
      })
    }
    this.setState({
      ipPatientData: patientData
    }, () => {
      this.getLayoutList()
      this.lodaerFunction(true)
      this.GetProvDiagnosisData()
      this.GetDiagnosisData()
      this.getTestList()
      this.getSpeciality()
      this.getCatagoryList()
      this.getVitalsList()
      this.getRadiologyList()
      setTimeout(() => {
        this.getDischargeSummary()
      }, 1000);
    })
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message,
      isSave : false
    })
  }
  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message,
      isSave : false
    })
  }
  lodaerFunction = (key) => {
    this.setState({
      isLoader: key
    }, () => {
      setTimeout(() => {
        this.setState({
          isLoader: false
        })
      }, 2000);
    })
  }

  GetProvDiagnosisData() {
    let admission_id = this.state.ipPatientData?.id  ? this.state.ipPatientData?.id : this.state.withOutAppointment?.ip_admission_id ? this.state.withOutAppointment?.ip_admission_id : this.state.ipPatientData?.ip_admission_id ? this.state.ipPatientData?.ip_admission_id : null;
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_PROVISIONAL_DIAG + "?ip_admission_id=" + admission_id + (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : "")).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              selectAdmissionProvisional: response.data.data.notes 
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  GetPreviousGetTests() {
    let admission_id = this.state.ipPatientData?.id  ? this.state.ipPatientData?.id : this.state.withOutAppointment?.ip_admission_id ? this.state.withOutAppointment?.ip_admission_id : this.state.ipPatientData?.ip_admission_id ? this.state.ipPatientData?.ip_admission_id : null;
    try {
      RestAPIService.getAll(Serviceurls.IP_PREVIOUS_TEST_RECORDS + "?ip_admission_id=" + admission_id +  (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : "")).
        then((response) => {
          if (response.data.status === "success" && response.data.data.length > 0) {
            let uniqueDates = [...new Set(response.data.data.map(item => item.result_completed_date.split("T")[0]))];
            let groupedData = uniqueDates.map(date => {
              return {
                date: date,
                data: response.data.data?.filter(item => item.result_completed_date.split("T")[0] === date)
              }
            });
            this.setState({
              TestPreviousData: groupedData
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message);
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getSpeciality = () => {
    try{
      RestAPIService.getAll(this.props?.isFromDoctor ? `${Serviceurls.FO_CONFIG_OPDOCTORNAME}?clinic_id=${getCachevalue('SelectedDoctorClinic')}` : Serviceurls.FO_CONFIG_OPDOCTORNAME).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              AllDoctorsList: response.data.data ? response.data.data?.filter((item) => (item.specialization_active === true)) : []
            })
          }
        }).catch((e) => {
          if (e?.response?.data?.status === 'fail') {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
      RestAPIService.getAll(this.props?.isFromDoctor ? `${Serviceurls.FO_CONFIG_OPSPECIALIZATION}?clinic_id=${getCachevalue('SelectedDoctorClinic')}` : Serviceurls.FO_CONFIG_OPSPECIALIZATION)
        .then((response => {
          if (response.data.status === 'success') {
            this.setState({
              splItem: response.data.data ? response.data.data : [],
              AllsplItem: response.data.data ? response.data.data : [],
            })
          }
        })).catch(e => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }

        })
    }catch(e){
      this.errorMessage(e.message)
    }
  }


  getCatagoryList = () => {
    try{
      RestAPIService.getAll(this.props?.isFromDoctor ? `${Serviceurls.IP_DIS_SUM_CATAGORY}?clinic_id=${getCachevalue('SelectedDoctorClinic')}` : Serviceurls.IP_DIS_SUM_CATAGORY)
        .then((response => {
          if (response.data.status === 'success') {
            this.setState({
              catagoryList: response.data.data ? response.data.data : []
            })
          }
        })).catch(e => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }

        })
    }catch(e){
      this.errorMessage(e.message)
    }
  }

  GetDiagnosisData() {
    let admission_id = this.state.ipPatientData?.id ? this.state.ipPatientData?.id : this.state.withOutAppointment?.ip_admission_id ? this.state.withOutAppointment?.ip_admission_id : this.state.ipPatientData?.ip_admission_id ? this.state.ipPatientData?.ip_admission_id : null;
    try {
      RestAPIService.getAll(Serviceurls.IP_DOCNOTES_DIAGNOSIS + "?ip_admission_id=" + admission_id + (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : "" )).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              icdADTblData: response.data.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getICDcode = () => {
    try {
      RestAPIService.getAll(Serviceurls.GET_ICD_CODE + '?search_key=' + this.state.icdCode +  (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : ""))
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              codeICDapi: response.data ? response.data.data : []
            })
          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })

    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  getLayoutList = () => {
    try {
      RestAPIService.getAll(this.props?.isFromDoctor ? `${Serviceurls.DIS_SUM_LAYOUT}?clinic_id=${getCachevalue('SelectedDoctorClinic')}` : Serviceurls.DIS_SUM_LAYOUT)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              disLayoutDesign: response.data ? response.data.data : [],
              AlldisLayoutDesign: response.data ? response.data.data : [],
            })
          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })

    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  getTestList = () => {
    let admission_id = this.state.ipPatientData?.id  ? this.state.ipPatientData?.id : this.state.withOutAppointment?.ip_admission_id ? this.state.withOutAppointment?.ip_admission_id : this.state.ipPatientData?.ip_admission_id ? this.state.ipPatientData?.ip_admission_id : null;
    try {
      if(this.state.isFinish === false){
      var { ipPatientData } = this.state
      RestAPIService.getAll(Serviceurls.IP_DISCHARGE_SUMMARY_TEST + "?ip_admission_id=" + admission_id + (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : ""))
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              testData: response.data.data ? response.data.data : []
            }, ()=>{ this.FilterTestDatas()})
          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
      }
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getVitalsList = () => {
    const admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.state.ipPatientData?.ip_admission_id || null;
    try {
      RestAPIService.getAll(Serviceurls.FO_IP_CLINICAL_NOTES_VITAL + "?ip_admission_id=" + admission_id + (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : ""))
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              vitalsTblData: response.data.data ? response.data.data : []
            })
          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getRadiologyList = () => {
    const admission_id = this.state.ipPatientData?.id || this.state.withOutAppointment?.ip_admission_id || this.state.ipPatientData?.ip_admission_id || null;
    try {
      RestAPIService.getAll(Serviceurls.FO_IP_DISCHARGE_SUMMARY_RADTEST + "?ip_admission_id=" + admission_id + (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : ""))
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              radTblData: response.data.data ? response.data.data : []
            })
          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getTemplateList = () => {
    try {
      if(this.state.selectLayout){
      RestAPIService.getAll(Serviceurls.IP_DIS_SUM_TEM + '?id=' + this.state.selectLayout + (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : ""))
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              disSumTemData: response.data ? response.data.data : [],
            })
          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
      }
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getDischargeSummary = () => {
    try {
      var states = this.state
      let admission_id = this.state.ipPatientData?.id || this.props?.ip_admission_id || this.state.withOutAppointment?.ip_admission_id || this.state.ipPatientData?.ip_admission_id;
      RestAPIService.getAll(Serviceurls.IP_DIS_SUM_POST + '?ip_admission_id=' + admission_id + '&patient_id=' + states.ipPatientData.patient_id + '&clinic_id=' + (getCachevalue('SelectedDoctorClinic') ? getCachevalue('SelectedDoctorClinic') : ""))
        .then(response => {
          if (response.data.status === 'success') {
            this.getSavedData(response?.data?.data?.length > 0 ? response?.data?.data : [], response?.data?.header_data ? response?.data?.header_data : {})
            this.setState({
              getDisSumData: response.data.data ? response.data.data : [],
              attenderName: response?.data?.header_data ? response.data.header_data.attender_name :'',
              attenderMobile: response?.data?.header_data ? response.data.header_data.attender_mobile :'',
              consultantName: response?.data?.header_data ? response.data.header_data.doctor_name :'',
            })
          }
        }).catch(e => {
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  getSavedData = (data, HeaderData) => {
    if (data.length == 1) {
      var tem_json = data[0].template_data
      this.setState({
        disSumID: data[0].id,
        isFinish: data[0].is_finished,
        selectLayout: data[0].layout_id,
        selectTemplate: data[0].template_id,
        selectSurDate: data[0].date_of_surgery,
        nextDatePicker: data[0].followup_date,
        reasonAdmission: data[0].reason_for_admission,
        DischargeType: data[0].discharge_type ? data[0].discharge_type : "Normal",
        disDateTimePicker: HeaderData.checkout_date ? new Date(HeaderData.checkout_date) : null,
        DischargeTime: HeaderData.checkout_time ? new Date(`${HeaderData.checkout_date}T${HeaderData.checkout_time}`) : null,
        selectSpeciality: data[0].speciality_id,
        selectedCategory: data[0].category_id,
        FilteredDoctorList : this.state.AllDoctorsList?.filter((list, index)=>(list.specialization_id === data[0].speciality_id)),
        doctor_name: this.state.AllDoctorsList.find((list, index)=>(list.doctor_id === data[0].discharge_doctor_id)),
      }, () => {
        var states = this.state
        var data1 = states.AlldisLayoutDesign?.filter((item) => (item.id == states.selectLayout))
        var keys = {}
        data1[0]?.dis_sum_json?.forEach((element) => {
          keys[element.field_name] = tem_json[element.field_name]
        })
        var splID = states.AllsplItem?.filter((item) => item.id === states.selectSpeciality)
        if(data[0].is_finished  || keys?.investigation_and_test_report?.test_report?.length > 0 ? (keys?.investigation_and_test_report?.test_report?.length-1).id == null : null){
        this.setState({
          postDisSumData: keys,
          icdADTblData: keys.admission_diagnosis ? keys.admission_diagnosis : [],
          selectAdmissionProvisional: tem_json.provisional_diagnosis ? tem_json.provisional_diagnosis : "",
          typeAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.type_of_anaesthesia : '',
          nameAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.name_of_anaesthetist : '',
          icdDDTblData: keys.discharge_diagnosis ? keys.discharge_diagnosis : [],
          selectDischargeProvisional: tem_json.discharge_provisional_diagnosis ? tem_json.discharge_provisional_diagnosis : "",
          medicationTbl: keys.medication ? keys.medication : [],
          inHospmedicationTbl: keys.in_hospital_medication ? keys.in_hospital_medication : [],
          selectDiet: keys.discharge_instruction ? keys.discharge_instruction.diet : '',
          selectActivity: keys.discharge_instruction ? keys.discharge_instruction.acivity : '',
          adviceDischarge: keys.discharge_instruction ? keys.discharge_instruction.advice_discharge : '',
          invetigation: keys.investigation_and_test_report ? keys.investigation_and_test_report.investigation : '',
          testgetData: keys.investigation_and_test_report ? keys.investigation_and_test_report.test_report : '',
          selectSpeciality: splID[0].name,
        }, ()=>{ this.FilterTestDatas()})
      }else{
        this.setState({
          postDisSumData: keys,
          icdADTblData: keys.admission_diagnosis ? keys.admission_diagnosis : [],
          selectAdmissionProvisional: tem_json.provisional_diagnosis ? tem_json.provisional_diagnosis : "",
          typeAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.type_of_anaesthesia : '',
          nameAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.name_of_anaesthetist : '',
          icdDDTblData: keys.discharge_diagnosis ? keys.discharge_diagnosis : [],
          selectDischargeProvisional: tem_json.discharge_provisional_diagnosis ? tem_json.discharge_provisional_diagnosis : "",
          medicationTbl: keys.medication ? keys.medication : [],
          inHospmedicationTbl: keys.in_hospital_medication ? keys.in_hospital_medication : [],
          selectDiet: keys.discharge_instruction ? keys.discharge_instruction.diet : '',
          selectActivity: keys.discharge_instruction ? keys.discharge_instruction.acivity : '',
          adviceDischarge: keys.discharge_instruction ? keys.discharge_instruction.advice_discharge : '',
          invetigation: keys.investigation_and_test_report ? keys.investigation_and_test_report.investigation : '',
          selectSpeciality: splID[0]?.name,
        })
      }
      })
      this.getTemplateList()
    }else{
      let CheckoutDateTime = HeaderData.checkout_date ? `${HeaderData.checkout_date}T${HeaderData.checkout_time ? HeaderData.checkout_time : "00:00:00"}` : null
      const inputTime = CheckoutDateTime ? new Date(CheckoutDateTime) : null;
      if (inputTime) {
        this.setState({
          disDateTimePicker: inputTime,
          DischargeTime: CheckoutDateTime ? (CheckoutDateTime.split("T")[1] === "00:00:00" ? null : inputTime) : null,
        })
      }
    }
  }
  
  getIpDrugChartDetails = () => {
    let admission_id = this.state.ipPatientData?.id ? this.state.ipPatientData?.id : this.state.withOutAppointment?.ip_admission_id ? this.state.withOutAppointment?.ip_admission_id : this.state.ipPatientData?.ip_admission_id ? this.state.ipPatientData?.ip_admission_id : null;
    try {
      RestAPIService.getAll(`${Serviceurls.IP_DRUG_CHART}?ip_admission_id=${admission_id}`)
        .then(response => {
          if (response.data.status === "success") {
            let data = JSON.parse(JSON.stringify(response.data.data))
            if (data?.length > 0) {
              let DrugChart = data?.map((item) => {
                let newData = {
                  medicinename: item?.drug_name ? item?.drug_name : "",
                  route: item?.route ? item?.route : '',
                  maen: item?.frequency ? item?.frequency : '',
                  dosage: item?.dosage_type ? `${item?.dosage_type} / ${item?.dosage_strength}` : item?.dosage_strength ? item?.dosage_strength : '',
                  instruction: item?.instruction ? item?.instruction : '',
                }
                // Check for duplicates
                if (this.state.inHospmedicationTbl?.some((list) => (list.medicinename === newData.medicinename && list.route === newData.route && list.maen === newData.maen && list.dosage === newData.dosage && list.instruction === newData.instruction))) {
                  this.errorMessage(`Already value added`);
                  return null
                } else {
                  return newData;
                }
              })?.filter(Boolean)
              this.setState({ inHospmedicationTbl: [...this.state.inHospmedicationTbl, ...DrugChart] })
            } else {
              this.errorMessage("No Data Found")
            }
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMsgFunc(error.response.data.message);
          } else {
            this.errorMsgFunc(error.message);
          }
        });
    } catch (e) {
      this.errorMsgFunc(e.message);
    }
  }

  postDischargeSummary = (key) => {
    try {
      var states = this.state

      var dischargeDate = new Date(states.disDateTimePicker)
      var dischargeTime = new Date(states.DischargeTime)
      var disDate = states.disDateTimePicker ? DateTime.fromJSDate(dischargeDate).toFormat('yyyy-MM-dd') : null
      var disTime = states.disDateTimePicker ? (states.DischargeTime ? DateTime.fromJSDate(dischargeTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS) : null) : null
      var nextDate = new Date(states.nextDatePicker)
      var nDate = states.nextDatePicker ? DateTime.fromJSDate(nextDate).toFormat('yyyy-MM-dd') : null
      var surDate = new Date(states.selectSurDate)
      var surgeryDate = states.selectSurDate ? DateTime.fromJSDate(surDate).toFormat('yyyy-MM-dd') : null
      var doctorID = states.doctor_name  ? states.doctor_name?.doctor_id : null
      var data = {
        "patient_id": states.ipPatientData.patient_id ? states.ipPatientData.patient_id : null,
        "ip_admission_id": states.ipPatientData.id ? states.ipPatientData.id : states.ipPatientData?.ip_admission_id ? states.ipPatientData?.ip_admission_id : null,
        "layout_id": states.selectLayout ? states.selectLayout : '',
        "template_id": states.selectTemplate ? states.selectTemplate : '',
        "template_data": states.disSumdata ? states.disSumdata : {},
        "date_of_surgery": surgeryDate,
        "discharged_date": disDate,
        "discharged_time": disTime,
        "discharge_doctor_id": doctorID,
        "followup_date": nDate,
        "reason_for_admission": states.reasonAdmission ? states.reasonAdmission : '',
        "discharge_type": states.DischargeType ? states.DischargeType : "Normal",
        "is_finished": key,
        "speciality_id": states.selectSpeciality.id,
        "category_id": states.selectedCategory
      }
      if (states.disSumID) {
        data['discharge_summary_id'] = states.disSumID
      }
      if (this.props?.isFromDoctor) {
        data['clinic_id'] = getCachevalue('SelectedDoctorClinic') ? +getCachevalue('SelectedDoctorClinic') : ""
      }
      this.lodaerFunction(true)
      RestAPIService.create(data, Serviceurls.IP_DIS_SUM_POST)
        .then(response => {
          if (response.data.status === 'success') {
            this.setState({
              completePop: false,
              isSave: false,
            })
            this.successMessage(response.data.message)
            this.getDischargeSummary()
          }
        }).catch(e => {
          this.setState({isSave: false})
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })

    } catch (e) {
      this.setState({isSave: false})
      this.errorMessage(e.message)
    }
  }

  getResultPrint = (value) => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${value.result_summary_id}&laboratory_id=${value.laboratory_id}` +  (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : "") )
      .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if(error?.response?.data?.status === 'fail'){
            this.errorMessage(error.response.data.message);
          }else{
            this.errorMessage(error.message)
          }
          
        })
    } catch (error) {
      this.errorMessage("PrintError");
    }
  }


  printDisSum() {
    try {
      var states = this.state
      var patientID = states.ipPatientData.patient_id ? states.ipPatientData.patient_id : null
      var admissionID = states.ipPatientData.id ? states.ipPatientData.id : states.withOutAppointment?.ip_admission_id ? states.withOutAppointment?.ip_admission_id : states.ipPatientData.ip_admission_id ? states.ipPatientData.ip_admission_id : null
      RestAPIService.PrintPDF(Serviceurls.IP_DIS_SUM_PRINT + "?ip_admission_id=" + admissionID + "&patient_id=" + patientID + "&export=pdf" +  (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : ""))
        .then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
            this.setState({isPrint: false,})
          }
        }).catch((e) => {
          this.setState({isPrint: false,})
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.setState({isPrint: false,})
      this.errorMessage(e.message)
    }
  }

  printRadiologyTest(id, radiology_id) {
    try {
      RestAPIService.PrintPDF(Serviceurls.RAD_RESULT_PRINT + '?id=' + id + "&radiology_id=" + radiology_id +
        "&export_type=pdf" + (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : ""))
        .then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
            this.setState({isRadPrint: false,})
          }
        }).catch((e) => {
          this.setState({isRadPrint: false,})
          if (e.response?.data?.status === "fail") {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.setState({isRadPrint: false,})
      this.errorMessage(e.message)
    }
  }

  renderDischargeHeader = () => {
    const { t, fromBillSummary } = this.props
    var states = this.state
    return (
      <Box component={'div'} className='eMed_IP_Discharge_Header'>
        <Box>
          <Typography paddingLeft={"0.5vw"}>{t('DischargeSummary')}</Typography>
        </Box>
        <Box className='eMed_IP_Discharge_Summary_Select'>
          <FormControl size='small' className='eMed_refdoc_txtbox'>
            <InputLabel id="demo-simple-select-label">{t('Discharge Type *')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              sx={{ backgroundColor: Colors.ThemeLightColor }}
              value={this.state.DischargeType}
              label={`${t('GrpbyCor')}*`}
              disabled={(states.isFinish === true || fromBillSummary) ? true : false}
              onChange={(event) => {
                this.setState({
                  DischargeType: event.target.value
                })
              }}
            >
              {
                DischargeTypeList.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <FormControl size='small' className='eMed_refdoc_txtbox'>
            <InputLabel id="demo-simple-select-label">{t('Select Category')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.selectedCategory}
              sx={{ backgroundColor: Colors.ThemeLightColor }}
              label={t('Select Category')}
              MenuProps={MenuProps}
              disabled ={(states.isFinish === true || fromBillSummary) ? true : false}
              onChange={(e) => {
                this.setState({
                  selectedCategory: e.target.value
                }, () => {
                  var states = this.state;
                  var filterLayout = states.AlldisLayoutDesign ? states.AlldisLayoutDesign?.filter((item) => item.category_id === states.selectedCategory) : [];
                  var ID = filterLayout.map((item) => item.speciality_id);
                  var filterSpl = states.AllsplItem?.filter((item) => ID.includes(item.id));
                  this.clearTemplateData()
                  this.setState({
                    splItem: filterSpl,
                    selectSpeciality : null,
                    selectLayout : "",
                    disLayoutDesign: [],
                    disSumTemData: [],
                    selectTemplate: "",
                  });
                })
              }}
            >
              {states.catagoryList?.length > 0 ? Array.isArray(states.catagoryList) && states.catagoryList.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.category_name}</MenuItem>
              )) : <Typography align='center'>{t('NDF')}</Typography>}


            </Select>
          </FormControl>
          <Autocomplete
            noOptionsText={states.specTxt === '' ? t('PLS') : t('NDF')}
            options={states.splItem}
            disabled={fromBillSummary ? true : states.isFinish ? true : this.state.selectedCategory ? false : true}
            getOptionLabel={(options) => typeof (options) === 'string' ? options : options.name__name}
            sx={{ backgroundColor: Colors.ThemeLightColor }}
            onChange={(e, value) => {
              this.setState({
                selectSpeciality: value,
              }, () => {
                if (value != null) {
                  var states = this.state
                  var filterLayout = states.AlldisLayoutDesign ? states.AlldisLayoutDesign?.filter((item) => item.speciality_id === value.id) : null
                  this.clearTemplateData()
                  this.setState({
                    disLayoutDesign: filterLayout,
                    FilteredDoctorList : this.state.AllDoctorsList?.filter((list, index)=>(list.specialization_id === value.id)),
                    doctor_name : null,
                    disSumTemData: [],
                    selectTemplate: "",
                  })
                } else {
                  this.getLayoutList()
                  this.setState({ selectLayout: '' })
                }
              })
            }}
            value={states.selectSpeciality}
            size='small'
            className='eMed_refdoc_txtbox'
            disablePortal
            id="Spec"
            renderInput={(params) => <TextField onChange={(event) => {
              const value = event.target.value
              if (value === "" || value != "") { this.setState({ specTxt: value }) }
            }} {...params} label={t('selectSpecial')} />}
          />
          <FormControl size='small' className='eMed_IP_Discharge_Select'>
            <InputLabel id="demolabel">{`${t('SelectLayout')} ${'(' + (states.disLayoutDesign.length > 0 ? states.disLayoutDesign.length : 0) + ')'}`}</InputLabel>
            <Select
              labelId="demolabel"
              id="demolabel"
              disabled={this.state.selectSpeciality ? false : true}
              MenuProps={MenuProps}
              value={states.selectLayout ? states.selectLayout: ''}
              label={`${t('SelectLayout')} ${'(' + (states.disLayoutDesign.length > 0 ? states.disLayoutDesign.length : 0) + ')'}`}
              readOnly={(states.isFinish === true || fromBillSummary) ? true : false}
              sx={{ backgroundColor: Colors.ThemeLightColor }}
              onChange={(event) => { this.handleLayout(event) }}
            >
              {states.disLayoutDesign?.length > 0 ? Array.isArray(states.disLayoutDesign) && states.disLayoutDesign.map((item) => (
                <MenuItem value={item.id}>{item.layout_name}</MenuItem>
              )) : <Typography align='center'>{t('NDF')}</Typography>}
            </Select>
          </FormControl>
          <FormControl size='small' className='eMed_IP_Discharge_Select'>
            <InputLabel id="Template">{`${t('SelectTemplate')} ${'(' + (states.disSumTemData.length > 0 ? states.disSumTemData.length : 0) + ')'}`}</InputLabel>
            <Select
              labelId="Template"
              id="Template"
              MenuProps={MenuProps}
              disabled={states.selectLayout === '' || states.selectLayout === undefined ? true : false}
              readOnly={(states.isFinish === true || fromBillSummary) ? true : false}
              value={states.selectTemplate}
              label={`${t('SelectTemplate')} ${'(' + (states.disSumTemData.length > 0 ? states.disSumTemData.length : 0) + ')'}`}
              sx={{ backgroundColor: Colors.ThemeLightColor }}
              onChange={this.handleTemplate}
            >
              {states.disSumTemData?.length > 0 ? Array.isArray(states.disSumTemData) && states.disSumTemData.map((item) => (
                <MenuItem value={item.id}>{item.template_name}</MenuItem>
              )) : <Typography align='center'>{t('NDF')}</Typography>}
            </Select>
          </FormControl>
          <Tooltip title="Copy from previous Discharge summary" arrow placement='top'>
            <IconButton disabled={Object.keys(this.state.postDisSumData ? this.state.postDisSumData : {}).length === 0 || this.state.selectLayout === ""} onClick={()=>{ this.setState({ OpenPrevCopyPop  : true })}} emed_tid="disSum_CopyPaste_Btn_TestId">
              <ContentCopy color={(Object.keys(this.state.postDisSumData ? this.state.postDisSumData : {}).length > 0  && this.state.selectLayout !== "") ?  "primary" : "disabled"}/>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    )
  }
  handleLayout = (event) => {
    var states = this.state
    if (states.disSumID && states.alertPop === false && states.selectLayout) {
      this.setState({
        alertPop: true,
        temLayId: event.target.value
      })
    } else {
      this.setState({
        selectLayout: event.target.value
      }, () => {
        this.getTemplateList()
        var states = this.state
        var data = states.disLayoutDesign?.filter((item) => (item.id == states.selectLayout))
        var keys = {}
        data[0]?.dis_sum_json?.forEach((element) => {
          keys[element.field_name] = ""
          keys["provisional_diagnosis"] = states.postDisSumData.provisional_diagnosis
        })
        this.setState({
          postDisSumData: keys
        })
      })
    }
  }
  handleTemplate = (event) => {
    var states = this.state    
    if (states.disSumID && states.notifyPop === false && states.selectTemplate) {
      this.setState({
        notifyPop: true,
        temraryID: event.target.value
      })
    } else {
      this.setState({
        selectTemplate: event.target.value
      }, () => {
        var states = this.state
        var tem_data = states.disSumTemData.find((item) => (
          item.id === states.selectTemplate
        ))        
        var data1 = states.disLayoutDesign?.filter((item) => (item.id == states.selectLayout))
        var keys = {}
        data1[0]?.dis_sum_json?.forEach((element) => {
          keys[element.field_name] = tem_data?.template_data[element.field_name]
        })
        if(states.isFinish || keys?.investigation_and_test_report?.test_report?.length > 0 ? keys?.investigation_and_test_report?.test_report[0].id == null : null){          
        this.setState({
          postDisSumData: keys,
          icdADTblData: keys.admission_diagnosis ? keys.admission_diagnosis : [],
          typeAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.type_of_anaesthesia : '',
          nameAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.name_of_anaesthetist : '',
          icdDDTblData: keys.discharge_diagnosis ? keys.discharge_diagnosis : [],
          medicationTbl: keys.medication ? keys.medication : [],
          inHospmedicationTbl: keys.in_hospital_medication ? keys.in_hospital_medication : [],
          selectDiet: keys.discharge_instruction ? keys.discharge_instruction.diet : '',
          selectActivity: keys.discharge_instruction ? keys.discharge_instruction.acivity : '',
          adviceDischarge: keys.discharge_instruction ? keys.discharge_instruction.advice_discharge : '',
          invetigation: keys.investigation_and_test_report ? keys.investigation_and_test_report.investigation : '',
          testgetData: keys.investigation_and_test_report ? keys.investigation_and_test_report.test_report : '',
          selectDischargeProvisional: tem_data.template_data.discharge_provisional_diagnosis ? tem_data.template_data.discharge_provisional_diagnosis : "",
          selectAdmissionProvisional: tem_data.template_data.provisional_diagnosis ? tem_data.template_data.provisional_diagnosis : ""
        }, ()=>{ this.FilterTestDatas() })
      }else{
        this.setState({
          postDisSumData: keys,
          icdADTblData: keys.admission_diagnosis ? keys.admission_diagnosis : [],
          typeAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.type_of_anaesthesia : '',
          nameAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.name_of_anaesthetist : '',
          icdDDTblData: keys.discharge_diagnosis ? keys.discharge_diagnosis : [],
          medicationTbl: keys.medication ? keys.medication : [],
          inHospmedicationTbl: keys.in_hospital_medication ? keys.in_hospital_medication : [],
          selectDiet: keys.discharge_instruction ? keys.discharge_instruction.diet : '',
          selectActivity: keys.discharge_instruction ? keys.discharge_instruction.acivity : '',
          adviceDischarge: keys.discharge_instruction ? keys.discharge_instruction.advice_discharge : '',
          invetigation: keys.investigation_and_test_report ? keys.investigation_and_test_report.investigation : '',
          selectDischargeProvisional: tem_data?.template_data?.discharge_provisional_diagnosis ? tem_data?.template_data?.discharge_provisional_diagnosis : "",
          selectAdmissionProvisional: tem_data?.template_data?.provisional_diagnosis ? tem_data?.template_data?.provisional_diagnosis : ""
        })
      }
      })
    }
  }

  renderDischargeDetails =  () => {
    const { t, fromBillSummary } = this.props
    var states = this.state
    var { ipPatientData } = this.state
    var currentDate = new Date(ipPatientData?.admission_date)
    var oneDateForm = new Date();
    let categoryType = this.state.catagoryList?.find((item) => item?.id === this.state.selectedCategory ? item : null)
    return (
      <Box component={'div'}>
        <Box component={'div'} margin={'1vw'} display={'flex'} justifyContent={'space-between'}>

          {/* date  of surgery */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={this.state.selectSurDate}
              name={"datePicker"}
              inputFormat='DD-MM-YYYY'
              maxDate={oneDateForm.setDate(currentDate.getDate() + 5)}
              minDate={new Date(ipPatientData.admission_date)}
              readOnly={(states.isFinish === true || fromBillSummary) ? true : false}
              label={t('DateofSurgery')}
              views={["year", "month", "day"]}
              onChange={
                (newDate) => {
                  this.setState({
                    selectSurDate: newDate,
                  });
                }}
              renderInput={(params) => <TextField style={{ width: '11vw' }} size='small' {...params} />}
            />
          </LocalizationProvider>

          {/* discharge date */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={this.state.disDateTimePicker}
              name={"datePicker"}
              readOnly={(states.isFinish === true || fromBillSummary) ? true : false}
              inputFormat='DD-MM-YYYY'
              label={categoryType?.category_type === 'Death' ? 'Death Date' : t('Discharge Date')}
              minDate={new Date(ipPatientData.admission_date)}
              onChange={
                (newDate) => {
                  if(newDate === null){
                    this.setState({
                      disDateTimePicker: null,
                      DischargeTime: null,
                    });
                  }else{
                    this.setState({
                      disDateTimePicker: newDate,
                    });
                  }
                }}
              renderInput={(params) => <TextField style={{ width: '11vw' }} size='small' {...params} />}
            />
          </LocalizationProvider>

          {/* discharge time */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label={categoryType?.category_type === 'Death' ? 'Death Time' : t('Discharge Time')}
              value={this.state.DischargeTime}
              disabled={!this.state.disDateTimePicker}
              onChange={(newStartTime) => this.setState({ DischargeTime: newStartTime })}
              renderInput={(props) => <TextField {...props} style={{ width: "11vw" }} size="small" />}
            />
          </LocalizationProvider>

          {/* follow up */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={this.state.nextDatePicker}
              name={"datePicker"}
              inputFormat='DD-MM-YYYY'
              readOnly={(states.isFinish === true || fromBillSummary) ? true : false}
              minDate={new Date(ipPatientData.admission_date)}
              label={t('Followup Date')}
              views={["year", "month", "day"]}
              onChange={
                (newDate) => {
                  this.setState({
                    nextDatePicker: newDate,
                  });
                }}
              renderInput={(params) => <TextField style={{ width: '11vw' }} size='small' {...params} />}
            />
          </LocalizationProvider>

          {/* doctor name */}
          <Autocomplete
            disabled={this.state.selectSpeciality ? false : true}
            clearIcon
            size='small'
            sx={{ width: '15vw' }}
            options={this.state.FilteredDoctorList}
            getOptionLabel={(options) => typeof (options) === 'string' ? options : options.doctor_name}
            value={this.state.doctor_name}
            onChange={(e, newValue) => { this.setState({ doctor_name: newValue }) }}
            renderInput={(params) => <TextField {...params} label={'Doctor Name'} sx={{ backgroundColor: 'white' }} type='search' />}
          />

          {/* reason for admisson */}
          <TextField
            size='small'
            id="outlined-basic"
            sx={{ width: '25vw' }}
            label="Reason for Admission*"
            variant="outlined"
            inputProps={{ maxLength: 90, readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
            value={this.state.reasonAdmission}
            onChange={(event) => {
              this.setState({
                reasonAdmission: event.target.value
              })
            }}
          />
        </Box>
        <hr />

        {/* from here we are going to add the patient details in the below function */}
        {/* this functions contains multiple values */}
        <Box component={'div'} overflow={'scroll'} height={'45.5vh'}>
          {this.renderDischargeSummary()}
        </Box>
      </Box>
    )
  }
  listenPopUp = ()=>{
    this.setState({
      listenPopUp:false
    })
  }
  
  voiceToTextgetter = (textStorage) => {
    if (textStorage != '') {
      switch (this.state.ClickKey) {
        case "Admission Provisional Diagnosis":
          if (this.state.selectAdmissionProvisional?.length) {
            this.setState({
              selectAdmissionProvisional: this.state.selectAdmissionProvisional + " " + textStorage,
            })
          }
          else {
            this.setState({
              selectAdmissionProvisional: textStorage,
            })
          }
          break;
        
          case  "Investigation":
            if (this.state.invetigation?.length) {
              this.setState({
                invetigation: this.state.invetigation + " " + textStorage,
              })
            }
            else {
              this.setState({
                invetigation: textStorage,
              })
            }
            break;
        case "Discharge Provisional Diagnosis":
          if (this.state.selectDischargeProvisional?.length) {
            this.setState({
              selectDischargeProvisional: this.state.selectDischargeProvisional + " " + textStorage,
            })
          }
          else {
            this.setState({
              selectDischargeProvisional: textStorage,
            })
          }
          break;
        case "Type of Anaesthesia":
          if (this.state.typeAnaesthesia?.length) {
            this.setState({
              typeAnaesthesia: this.state.typeAnaesthesia + " " + textStorage,
            })
          }
          else {
            this.setState({
              typeAnaesthesia: textStorage,
            })
          }
          break;
        case "Name of Anaesthetist":
          if (this.state.nameAnaesthesia?.length) {
            this.setState({
              nameAnaesthesia: this.state.nameAnaesthesia + " " + textStorage,
            })
          }
          else {
            this.setState({
              nameAnaesthesia: textStorage,
            })
          }
          break;
        case "AdviceonDischarge*":
          if (this.state.adviceDischarge?.length) {
            this.setState({
              adviceDischarge: this.state.adviceDischarge + " " + textStorage,
            })
          }
          else {
            this.setState({
              adviceDischarge: textStorage,
            })
          }
          break;
        case "Activity":
          if (this.state.selectActivity?.length) {
            this.setState({
              selectActivity: this.state.selectActivity + " " + textStorage,
            })
          }
          else {
            this.setState({
              selectActivity: textStorage,
            })
          }
          break;
        case "Diet":
          if (this.state.selectDiet?.length) {
            this.setState({
              selectDiet: this.state.selectDiet + " " + textStorage,
            })
          }
          else {
            this.setState({
              selectDiet: textStorage,
            })
          }
          break;
      }
      let states = this.state
      states.ClickKey = ""
      this.setState({
        states
      })

      if (this.state.tempKey != '') {
        let key = this.state.tempKey
        let states = this.state
        states.postDisSumData[key] = states.postDisSumData[key]?.length ? states.postDisSumData[key] + " " + textStorage : textStorage
        this.setState({
          states
        })
        states.tempKey =""
        this.setState({
          states
        })
      }

    }
    this.setState({
      listenPopUp: false
    })
  }

  setEditedData = (stateName, value, postState) => {
    let states = this.state
    if(postState != "" && postState != undefined){
      states.postDisSumData[postState] = value
      this.setState({
        states
      },()=>{
        this.setState({
          isTemplateChanged: false
        })
      })
    }else{
      states[stateName] = value
      this.setState({ states },()=>{
        this.setState({
          isTemplateChanged: false
        })
      })
    }    
  } 

  renderDischargeSummary = () => {
    var states = this.state
    var data = states.disLayoutDesign
    var datalabel = data?.filter((item) => (item.id == states.selectLayout))
    let { fromBillSummary } = this.props;
    let disable = states.isFinish === true || fromBillSummary ? true : false          
    return (
      <Box>
        {datalabel.length === 1 ? datalabel[0].dis_sum_json.map((item) => {
          if (item.label === "Admission Diagnosis" && item.is_active === true) {
            return (
             <Box>
              {this.renderadmissionDiagnosis("Admission Diagnosis", item.is_mantatory)}
              <Box className='eMed_DisSum_Lay_CommonBox1'>
              {/* <TextField
                sx={{ width: "90vw" }}
                id="icd-code"
                multiline={true}
                // rows={3}
                name={"selectAdmissionProvisional"}
                label={"Admission Provisional Diagnosis"}
                variant="outlined"
                value={states.selectAdmissionProvisional ? states.selectAdmissionProvisional : ""}
                InputProps={{
                  endAdornment:<InputAdornment position="end">
                     <MicIcon sx={{ color:  grey[1000] ,cursor:"pointer" }} onClick={()=>{this.setState({listenPopUp:true , ClickKey : "Admission Provisional Diagnosis" ,tempKey:"provisional_diagnosis"  })}}></MicIcon >
                   </InputAdornment>
                }}
                onChange={(event) => {
                  this.setState({
                    selectAdmissionProvisional: event.target.value
                  })
                }}
              /> */}

              <ToolsFieldWithMic
                Title={"Admission Provisional Diagnosis"} 
                EditorRef={states.selectAdmissionProvisional ? states.selectAdmissionProvisional : ''} 
                EditorDisable={disable}
                saveData={this.setEditedData.bind(this)} 
                isFrom="dischargeSummary"
                minHeight={"12vh"}
                overallHeight={"auto"}
                stateName='selectAdmissionProvisional'  
              />
              </Box>
            </Box> 
            )
          } else if (item.label === "Discharge Diagnosis" && item.is_active === true) {
            return (
              <Box>
                {this.renderadmissionDiagnosis("Discharge Diagnosis", item.is_mantatory)}
                  <Box className='eMed_DisSum_Lay_CommonBox1'>
                    {/* <TextField
                      sx={{ width: "90vw" }}
                      id="icd-code"
                      multiline={true}
                      // rows={3}
                      name={"selectDischargeProvisional"}
                      label={"Discharge Provisional Diagnosis"}
                      variant="outlined"
                      value={states.selectDischargeProvisional}
                      onChange={(event) => {
                        this.setState({
                          selectDischargeProvisional: event.target.value
                        })
                      }}
                      InputProps={{
                        endAdornment:<InputAdornment position="end">
                           <MicIcon  sx={{ color:  grey[1000] ,cursor:"pointer" }} onClick={()=>{this.setState({listenPopUp:true,ClickKey :"Discharge Provisional Diagnosis", tempKey :"discharge_provisional_diagnosis"}) }}></MicIcon >
                         </InputAdornment>
                      }}
                    /> */}

                  <ToolsFieldWithMic
                    Title={"Discharge Provisional Diagnosis"} 
                    EditorRef={states.selectDischargeProvisional ? states.selectDischargeProvisional : ''} 
                    EditorDisable={disable}
                    saveData={this.setEditedData.bind(this)} 
                    isFrom="dischargeSummary"
                    minHeight={"12vh"}
                    overallHeight={"auto"}
                    stateName='selectDischargeProvisional'  
                  />
                  </Box> 
              </Box>
            )
          } else if (item.label === 'Name Anaesthetist and Type of Anaesthesia' && item.is_active === true) {
            return (
              <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "flex-start", gap: "2vw", marginLeft: '1vw', width:'90vw'}}>
                {/* <TextField
                  size='small'
                  label={item.is_mantatory ? "Type of Anaesthesia*" : "Type of Anaesthesia"}
                  sx={{ width: '44vw' }}
                  id="icd-cde"
                  inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
                  placeholder='Enter Report Name'
                  variant="outlined"
                  value={this.state.typeAnaesthesia}
                  onChange={(event) => {
                    this.setState({
                      typeAnaesthesia: event.target.value
                    })
                  }}
                  InputProps={{
                    endAdornment:<InputAdornment position="end">
                       <MicIcon  sx={{ color:  grey[1000] ,cursor:"pointer" }} onClick={()=>{this.setState({listenPopUp:true, ClickKey : "Type of Anaesthesia" ,tempKey :"type_of_anaesthesia"}) }}></ MicIcon>
                     </InputAdornment>
                  }}
                /> */}
                <div style={{width:"48%"}}>
                  <ToolsFieldWithMic
                    Title={item.is_mantatory ? "Type of Anaesthesia*" : "Type of Anaesthesia"} 
                    EditorRef={states.typeAnaesthesia ? states.typeAnaesthesia : ''} 
                    EditorDisable={disable}
                    saveData={this.setEditedData.bind(this)} 
                    isFrom="dischargeSummary"
                    minHeight={"12vh"}
                    overallHeight={"auto"}
                    stateName='typeAnaesthesia'  
                  />
                </div>
                {/* <TextField
                  size='small'
                  label={item.is_mantatory ? "Name of Anaesthetist*" : "Name of Anaesthetist"}
                  sx={{ width: '44vw' }}
                  id="icd-cde"
                  inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
                  placeholder='Enter Report Name'
                  variant="outlined"
                  value={this.state.nameAnaesthesia}
                  onChange={(event) => {
                    this.setState({
                      nameAnaesthesia: event.target.value
                    })
                  }}
                  InputProps={{
                    endAdornment:<InputAdornment position="end">
                       <MicIcon  sx={{ color:  grey[1000] ,cursor:"pointer" }} onClick={()=>{this.setState({listenPopUp:true, ClickKey : "Name of Anaesthetist", tempKey : "name_of_anaesthetist" }) }}></ MicIcon>
                     </InputAdornment>
                  }}
                /> */}
                <div style={{width:"48%"}}>
                  <ToolsFieldWithMic
                    Title={item.is_mantatory ? "Name of Anaesthetist*" : "Name of Anaesthetist"} 
                    EditorRef={states.nameAnaesthesia ? states.nameAnaesthesia : ''} 
                    EditorDisable={disable}
                    saveData={this.setEditedData.bind(this)} 
                    isFrom="dischargeSummary"
                    minHeight={"12vh"}
                    overallHeight={"auto"}
                    stateName='nameAnaesthesia'  
                />
                </div>
              </Box>
            )
          } else if (item.label === "Medication" && item.is_active === true) {
            return (
              this.renderMedication(item.is_mantatory)
            )
          } else if (item.label === "In Hospital Medication" && item.is_active === true) {
            return (
              this.renderinHospMedication(item.is_mantatory)
            )
          } else if (item.label === "Discharge Instruction" && item.is_active === true) {
            return (
              this.renderDisInstruction(item.is_mantatory)
            )
          } else if (item.label === 'Investigation and Test Report' && item.is_active === true) {
            return (
              <Box className='eMed_DisSum_Lay_CommonBox1'>
                {/* <TextField
                  label={item.is_mantatory ? "Investigation*" : "Investigation"}
                  sx={{ width: '90vw' }}
                  id="icd-cde"
                  multiline={true}
                  // rows={3}
                  inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
                  placeholder='Enter Report Name'
                  variant="outlined"
                  value={this.state.invetigation}
                  InputProps={{
                    endAdornment:<InputAdornment position="end">
                       <MicIcon  sx={{ color:  grey[1000] ,cursor:"pointer" }} onClick={()=>{this.setState({listenPopUp:true , ClickKey : "Investigation"  ,tempKey :"investigation" })}}></ MicIcon>
                     </InputAdornment>
                  }}
                  onChange={(event) => {
                    this.setState({
                      invetigation: event.target.value
                    })
                  }}
                /> */}
                <ToolsFieldWithMic
                  Title={item.is_mantatory ? "Investigation*" : "Investigation"} 
                  EditorRef={states.invetigation ? states.invetigation : ''} 
                  EditorDisable={disable}
                  saveData={this.setEditedData.bind(this)} 
                  isFrom="dischargeSummary"
                  minHeight={"12vh"}
                  overallHeight={"auto"}
                  stateName='invetigation'  
              />
                {this.renderTestTbl()}
              </Box>
            )
          } else if (item.label === "Vitals" && item.is_active === true) {
            return (this.renderVitalsTbl())
          } else if (item.label === "Radiology Report" && item.is_active === true) {
            return (this.renderRadReportTbl())
          } else {
            return (
              this.renderTextBoxs(item.label, '90vw', item.field_name, item.is_mantatory, true, item.is_active)
            )
          }
        }) : <Box className='eMed_DisSum_Tem_Nolayout'>
          <Typography color={'#888888'}>{'Please Select the Layout and Template'}</Typography>
        </Box>}
      </Box>
    )
  }

  // Admission Diagnosis field
  renderadmissionDiagnosis = (label, mantatory) => {
    var states = this.state
    var { icdADTblData, icdDDTblData } = this.state
    const { t, fromBillSummary } = this.props
    return (
      <Box className='eMed_DisSum_Lay_CommonBox'>
        <Box className='eMed_DisSum_Lay_AdmDig'>
          <Typography>{mantatory ? label + "*" : label}</Typography>
          <Autocomplete
            noOptionsText={states.icdCode === '' ? t('PLS') : t('NDF')}
            options={states.codeICDapi}
            getOptionLabel={(options) => options.icd_code + " | " + options.icd_short_desc + " | " + options.icd_desc}
            onChange={(e, value) => {

              this.setState({
                testRow: value,
                selecticd: value
              })
            }}
            value={states.selecticd}
            size='small'
            className='eMed_DisSum_Icd_TxtBox'
            disablePortal
            readOnly={(states.isFinish === true || fromBillSummary) ? true : false}
            id="combobox-demo"
            renderInput={(params) => <TextField onChange={(event) => {
              const value = event.target.value
              if (value === "" || value != "") { this.setState({ icdCode: value }, () => { this.getICDcode() }) }
            }} {...params} placeholder="Enter ICD Code / Short Description / Long Description" />}
          />
          <Button id='eMed_Config_btn' disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => { label === "Admission Diagnosis" ? this.addAdmissionDig() : this.addDisDignosis() }} variant='contained'>{t('Add')}</Button>
        </Box>
        <Box sx={{ marginTop: '1vw' }}>
          <Paper elevation={0}>
            <TableContainer sx={{ minHeight: '23vh' }} component={Paper}>
              <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
                <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                  <TableRow>
                    <TableCell width={'5vw'}>S.No</TableCell>
                    <TableCell width={'20vw'}>ICD 10 Codes</TableCell>
                    <TableCell width={'35vw'}>Short Description</TableCell>
                    <TableCell width={'35vw'}>Long Description</TableCell>
                    <TableCell width={'5vw'}>Action</TableCell>
                  </TableRow>
                </TableHead>
                {label === "Admission Diagnosis" ? icdADTblData?.length > 0 ? Array.isArray(icdADTblData) && icdADTblData.map((item, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell width={'5vw'}>{index + 1}</TableCell>
                      <TableCell width={'20vw'}>{item.icd_code ? item.icd_code : item.icd_codes}</TableCell>
                      <TableCell width={'35vw'}>
                        {item.icd_short_desc?.length > 40 ?
                          <Tooltip title={item.icd_short_desc} placement='top' arrow>
                            <Typography>{item.icd_short_desc.slice(0, 40) + '...'}</Typography>
                          </Tooltip> : <Typography>{item.icd_short_desc}</Typography>}
                      </TableCell>
                      <TableCell width={'35vw'}>
                        {item.icd_desc?.length > 40 ?
                          <Tooltip title={item.icd_desc} placement='top' arrow>
                            <Typography>{item.icd_desc.slice(0, 40) + '...'}</Typography>
                          </Tooltip> : <Typography>{item.icd_desc}</Typography>}</TableCell>
                      <TableCell width={'5vw'}>
                        <Box className='eMed_Dis_Tem_ActionBtn'>
                          <Tooltip title={'Delete'} placement='top' arrow>
                            <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => {
                              icdADTblData.splice(index, 1)
                              this.setState({
                                icdADTblData
                              })
                            }}>
                              {<img className='eMed_DisSum_Lay_StarIcon' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} alt='Delete' />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )) : null : icdDDTblData?.length > 0 ? Array.isArray(icdDDTblData) && icdDDTblData.map((item, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell width={'5vw'}>{index + 1}</TableCell>
                      <TableCell width={'20vw'}>{item.icd_code}</TableCell>
                      <TableCell width={'35vw'}>
                        {item.icd_short_desc?.length > 40 ?
                          <Tooltip title={item.icd_short_desc} placement='top' arrow>
                            <Typography>{item.icd_short_desc.slice(0, 40) + '...'}</Typography>
                          </Tooltip> : <Typography>{item.icd_short_desc}</Typography>}
                      </TableCell>
                      <TableCell width={'35vw'}>
                        {item.icd_desc?.length > 40 ?
                          <Tooltip title={item.icd_desc} placement='top' arrow>
                            <Typography>{item.icd_desc.slice(0, 40) + '...'}</Typography>
                          </Tooltip> : <Typography>{item.icd_desc}</Typography>}</TableCell>
                      <TableCell width={'5vw'}>
                        <Box className='eMed_Dis_Tem_ActionBtn'>
                          <Tooltip title={'Delete'} placement='top' arrow>
                            <IconButton disabled={(states.isFinish === true || fromBillSummary) ? true : false} onClick={() => {
                              icdDDTblData.splice(index, 1)
                              this.setState({
                                icdDDTblData
                              })
                            }}>
                              {<img className='eMed_DisSum_Lay_StarIcon' src={(states.isFinish === true || fromBillSummary) ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} alt='Delete' />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )) : null}
              </Table>
              {label === "Admission Diagnosis" ? icdADTblData?.length === 0 ?
                <Box className='eMed_Dis_Template_NoRecord'>
                  <Typography >{t('NoRecordsFound')}</Typography>
                </Box> : null : icdDDTblData?.length === 0 ?
                <Box className='eMed_Dis_Template_NoRecord'>
                  <Typography >{t('NoRecordsFound')}</Typography>
                </Box> : null}
            </TableContainer>
          </Paper>
        </Box>
      </Box>
    )
  }

  //  Admission Diagnosis button function
  addAdmissionDig = () => {
    var states = this.state
    var duplicate = this.admissonDiagdulpicate()
    if (states.selecticd &&
      !duplicate) {
      states["icdADTblData"].push({
        "icd_code": states.selecticd.icd_code,
        "icd_short_desc": states.selecticd.icd_short_desc,
        "icd_desc": states.selecticd.icd_desc
      })
      this.setState({ states }, () => { this.setState({ selecticd: null, icdCode: '', codeICDapi: [] }) })
    } else if (duplicate) {
      this.errorMessage('Already ICD Code Added')
    } else {
      this.errorMessage("Please Enter the ICD Details")
    }
  }

  // Discharge Diagnosis button function
  addDisDignosis = () => {
    var states = this.state
    var duplicate = this.DischargeDiagdulpicate()
    if (states.selecticd &&
      !duplicate) {
      states["icdDDTblData"].push({
        "icd_code": states.selecticd.icd_code,
        "icd_short_desc": states.selecticd.icd_short_desc,
        "icd_desc": states.selecticd.icd_desc
      })
      this.setState({ states }, () => { this.setState({ selecticd: null, icdCode: '', codeICDapi: [] }) })
    } else if (duplicate) {
      this.errorMessage('Already ICD Code Added')
    } else {
      this.errorMessage("Please Enter the ICD Details")
    }
  }

  admissonDiagdulpicate = () => {
    var { selecticd, icdADTblData } = this.state
    var duplicate = false
    if (selecticd?.icd_desc) {
      for (let i = 0; i < icdADTblData?.length; i++) {
        if (icdADTblData[i].icd_code == selecticd.icd_code) {
          duplicate = true
        }
      }
    }
    return duplicate
  }

  DischargeDiagdulpicate = () => {
    var { selecticd, icdDDTblData } = this.state
    var duplicate = false
    if (selecticd?.icd_desc) {
      for (let i = 0; i < icdDDTblData?.length; i++) {
        if (icdDDTblData[i].icd_code == selecticd.icd_code) {
          duplicate = true
        }
      }
    }
    return duplicate
  }

  renderTextBoxs = (label, width, key, mantatory, isrow, is_active) => {
    let { fromBillSummary } = this.props;
    var states = this.state
    let disable = states.isFinish === true || fromBillSummary ? true : false    
    if (is_active && label != "Provisional Diagnosis" && label != "Discharge Diagnosis") {
      return (
        <Box className='eMed_DisSum_Lay_CommonBox1'>
          {/* <TextField
            sx={{ width: width }}
            id="icd-code"
            multiline={isrow}
            // rows={label === "Procedure/Treatment Given During Hospitalization" ? undefined : 3}
            name={key}
            inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
            label={mantatory ? label + "*" : label}
            variant="outlined"
            value={states.postDisSumData[key] ? states.postDisSumData[key] : ''}
            onChange={(event) => {
              states.postDisSumData[key] = event.target.value;
              this.setState({
                states
              });
            }}
            InputProps={{
              endAdornment:<InputAdornment position="end">
                 <MicIcon  sx={{ color: grey[1000] ,cursor:"pointer" }} onClick={()=>{this.setState({listenPopUp:true, tempKey : key }) }}></MicIcon>
               </InputAdornment>
            }} 
          /> */}
            <div className="discharge_Sum_outer_container">
              <ToolsFieldWithMic
                Title={mantatory ? label + "*" : label} 
                EditorDisable={disable}
                EditorRef={states.postDisSumData[key] ? states.postDisSumData[key] : ''} 
                saveData={this.setEditedData.bind(this)} 
                isFrom="dischargeSummary"
                minHeight={"12vh"}
                overallHeight={"auto"}
                postState={key}  
              />
            </div>
        </Box>
      )
    }
  }

  FilterTestDatas = (Showerror = false) => {
    try {
      var states = this.state
      var arr1 = states.isFinish ? [] : states.testData
      var arr2 = states.testgetData?.filter((item)=>(item?.from_previous_data ));
      let test = arr1.concat(arr2)
      test.sort((x,y) => new Date(x.result_completed_date) - new Date(y.result_completed_date))

      var uniqueArray = [];
      var uniqueIds = new Set();
      test.forEach((obj) => {
        if (obj && obj.id && !uniqueIds.has(obj.id)) {
          uniqueArray.push(obj);
          uniqueIds.add(obj.id);
        }
      });
      if (this.state.FilteredTestData.length === uniqueArray.length && Showerror) {
        this.errorMessage("Test Already Added")
      }
      this.setState({
        FilteredTestData: uniqueArray
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  methodFourhandleOpen = () => {
    this.setState({
        methodFourOpen: true,
    })
}

  culturTestView = (result_list) => {
    const { t } = this.props
    try {
      let states = this.state
      let { methodThreeResult } = this.state;
      return (
        <>
          <Button
            variant='contained'
            onClick={() => {
              this.setState({
                selectedRowId: result_list?.id,
                selected_res_three: JSON.stringify(result_list)
              })
              this.methodFourhandleOpen()
            }}
            sx={{ textTransform: "capitalize" }}
          >View Result</Button>
          {result_list.id === this.state.selectedRowId ?
            <Modal
              open={this.state.methodFourOpen}
              onClose={() => this.setState({ methodFourOpen: false })}>
              <Box className='emedhub_popup' sx={{ width: "80vw" }}>
                <div className=''>
                  <div className='emedhub_popup_title'>
                    <label className='emedhub_popup_title_left'>{t("TestReport")}</label>
                    <div className='emedhub_card_close_button'>
                      <img
                        className='emedhub_popup_title_right'
                        src={ImagePaths.LabCloseButton.default}
                        onClick={() => {this.setState({methodFourOpen: false})}}
                        alt='close'
                      />
                    </div>
                  </div>
                  <div className='emedhub_popup_title_2' style={{ marginTop: "0.5vw" }}>
                    <Typography className='emedhub_popup_title_Header'>{t("CulptureTest")}</Typography>
                  </div>
                </div>
                <div style={{ width: "77.5vw", height: "35vw", margin: "1vw",  }}>
                  <Typography sx={{ margin: "auto" }}></Typography>
                  <div style={{ height: "78vh", overflow: "scroll", marginTop: "1vw",  }}>
                    {result_list?.result?.value?.length > 0 ? result_list?.result?.value?.map((items, indexs) => (
                      <div className="emedhub_card_method_4_table">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "70vw", marginTop: "1.3vw", marginBottom: "1vw" }}>
                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Box component={'div'} sx={{ display: "flex" }}>
                              <Typography>{`ORGANISM ISOLATED 0${indexs + 1} : `}</Typography>
                              <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                {items?.bacteria ? items?.bacteria?.length > 20 ?
                                  <Tooltip placement='top' title={items?.bacteria}>
                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                      {items?.bacteria.slice(0, 20) + '...'}
                                    </Typography>
                                  </Tooltip> :
                                  <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.bacteria}</Typography> : '-'}
                              </Typography>
                            </Box>
                          </div>
                          <Box component={'div'} sx={{ display: "flex" }}>
                            <Typography>{`MICROSCOPY : `}</Typography>
                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                              {items?.microscopy ? items?.microscopy?.length > 20 ?
                                <Tooltip placement='top' title={items?.microscopy}>
                                  <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                    {items?.microscopy.slice(0, 20) + '...'}
                                  </Typography>
                                </Tooltip> :
                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.microscopy}</Typography> : '-'}
                            </Typography>
                          </Box>
                          <Box component={'div'} sx={{ display: "flex" }}>
                            <Typography>{`COLONY COUNT : `}</Typography>
                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                              {items?.colony_count ? items?.colony_count?.length > 20 ?
                                <Tooltip placement='top' title={items?.colony_count}>
                                  <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                    {items?.colony_count.slice(0, 20) + '...'}
                                  </Typography>
                                </Tooltip> :
                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.colony_count}</Typography> : '-'}
                            </Typography>
                          </Box>
                          <Box component={'div'} sx={{ display: "flex" }}>
                            <Typography>{`METHOD : `}</Typography>
                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                              {items?.method_type ? items?.method_type?.length > 20 ?
                                <Tooltip placement='top' title={items?.method_type}>
                                  <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                    {items?.method_type.slice(0, 20) + '...'}
                                  </Typography>
                                </Tooltip> :
                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.method_type}</Typography> : '-'}
                            </Typography>
                          </Box>
                        </div>
                        <TableContainer style={{ maxHeight: "35vh" }}>
                          <Table style={{ border: "1px solid gray" }} stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("SNo")}</b></TableCell>
                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Antibiotic")}</b></TableCell>
                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("MIC Result/Range")}</b></TableCell>
                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Zone Result/Range")}</b></TableCell>
                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Interpretaion")}</b></TableCell>
                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Efficacy Ratio")}</b></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {items?.antibacteria?.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>
                                    {item?.antibiotic ? item?.antibiotic?.length > 20 ?
                                      <Tooltip placement='top' title={item?.antibiotic}>
                                        <Typography>
                                          {item?.antibiotic.slice(0, 20) + '...'}
                                        </Typography>
                                      </Tooltip> :
                                      <Typography>{item?.antibiotic}</Typography> : '-'}
                                  </TableCell>
                                  <TableCell>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                      <Typography>{item?.mic_break_point ? item?.mic_break_point?.length > 20 ?
                                        <Tooltip placement='top' title={item?.mic_break_point}>
                                          <div>{item?.mic_break_point.slice(0, 20) + '...' + " "}</div>
                                        </Tooltip> : (item?.mic_break_point + " ") : '-'}</Typography>
                                      {item?.mic_break_point_std ?
                                        <Typography style={{ marginLeft: "0.25vw" }}>{item?.mic_break_point_std ? item?.mic_break_point_std?.length > 20 ?
                                          <Tooltip placement='top' title={item?.mic_break_point_std}>
                                            <div>{" / " + item?.mic_break_point_std.slice(0, 20) + '...'}</div>
                                          </Tooltip> : (" / " + item?.mic_break_point_std) : '-'}</Typography> : null}
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                      <Typography>{item?.zone_size ? item?.zone_size?.length > 20 ?
                                        <Tooltip placement='top' title={item?.zone_size}>
                                          <div>{item?.zone_size.slice(0, 20) + '...' + " "}</div>
                                        </Tooltip> : (item?.zone_size + " ") : '-'}</Typography>
                                      {item?.zone_size_std ?
                                        <Typography style={{ marginLeft: "0.25vw" }}>{item?.zone_size_std ? item?.zone_size_std?.length > 20 ?
                                          <Tooltip placement='top' title={item?.zone_size_std}>
                                            <div>{" / " + item?.zone_size_std.slice(0, 20) + '...'}</div>
                                          </Tooltip> : (" / " + item?.zone_size_std) : '-'}</Typography> : null
                                      }
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {item?.interpretation ? item?.interpretation?.length > 20 ?
                                      <Tooltip placement='top' title={item?.interpretation}>
                                        <Typography>
                                          {item?.interpretation.slice(0, 20) + '...'}
                                        </Typography>
                                      </Tooltip> :
                                      <Typography>{item?.interpretation}</Typography> : '-'}
                                  </TableCell>
                                  <TableCell>
                                    {item?.methodThreeBacteriaResult ? item?.methodThreeBacteriaResult?.length > 20 ?
                                      <Tooltip placement='top' title={item?.methodThreeBacteriaResult}>
                                        <Typography>
                                          {item?.methodThreeBacteriaResult.slice(0, 20) + '...'}
                                        </Typography>
                                      </Tooltip> :
                                      <Typography>{item?.methodThreeBacteriaResult}</Typography> : '-'}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    )) : <div style={{ marginTop: "1.5vw", width: "75vw", marginLeft: "1.25vw", height: "50vh" }}>
                      <TableContainer style={{ border: "1px solid black" }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("SNo")}</b></TableCell>
                              <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Antibiotic")}</b></TableCell>
                              <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("MIC Result/Range")}</b></TableCell>
                              <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Zone Result/Range")}</b></TableCell>
                              <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Interpretaion")}</b></TableCell>
                              <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Efficacy Ratio")}</b></TableCell>
                              <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Action")}</b></TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </TableContainer>
                      <div style={{ marginTop: "10vw", marginLeft: "32vw" }}>
                        <div>No Records To Be Shown</div>
                      </div>
                    </div>}
                  </div>
                </div>
              </Box>
            </Modal> : null}
              </>
          )
      } catch (error) {
          this.setState({
              isErrorMsg: true,
              isErrorMsgText: t("ManitoryFields")
          })
      }
  }

  renderTestTbl = () => {
    const { t } = this.props
    var states = this.state
    return (
      <Box className='eMed_DisSum_Lay_CommonBox1'>
        <Box sx={{ display:'flex', flexDirection:'row', alignItems:'center'}}>
        <Typography>{"Test Reports"}</Typography>
          <Tooltip title='Previous OP tests' placement='top' arrow>
            <IconButton
              onClick={() => { this.GetPreviousGetTests() }}
            >
              <Box component={'img'} src={ImagePaths.DisSumTestGet.default} height={'1.3vw'} width={'1.3vw'} />
            </IconButton>
          </Tooltip>
        {
          this.state.TestPreviousData?.length > 0 ? Array.isArray(this.state.TestPreviousData) && this.state.TestPreviousData.map((item, index) => (
            <Button variant='outlined' size='small' sx={{marginRight:'0.5vw'}} onClick={() => {
              let PrevData = []
              item.data.forEach((element) => {
                element.from_previous_data = true
                PrevData.push(element)
              })
              this.setState({
                testgetData: [...this.state.testgetData, ...PrevData]
              }, ()=> { this.FilterTestDatas(true) })
            }}>{formatDate(item.date)}</Button>
          )) : null
        }
        </Box>
        <Paper elevation={0} sx={{ height: '40vh', overflow: 'scroll' }}>
          <TableContainer component={'Paper'}>
            <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                <TableRow>
                  <TableCell sx={{width:'5vw'}}>{"S.No"}</TableCell>
                  <TableCell sx={{width:'20vw'}}>{"Test Name/Test Group"}</TableCell>
                  <TableCell sx={{width:'20vw'}}>{"Reference Range"}</TableCell>
                  <TableCell sx={{width:'20vw'}}>{"Result"}</TableCell>
                  <TableCell sx={{width:'18vw'}}>{"Tested On"}</TableCell>
                  <TableCell sx={{width:'15vw'}}>{"Turn Over Time"}</TableCell>
                  <TableCell sx={{width:'2vw'}}>{"Action"}</TableCell>
                </TableRow>
              </TableHead>
              {states?.FilteredTestData?.length > 0 ? Array.isArray(states.FilteredTestData) && states?.FilteredTestData.map((item, index) => (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{width:'5vw'}}>{index + 1}</TableCell>
                    <TableCell sx={{width:'20vw'}}>{(item.lab_test_name && item.lab_set_name) ? (item.lab_test_name + ' / ' + item.lab_set_name) : item.lab_test_name ? item.lab_test_name : item.lab_set_name ? item.lab_set_name : '-'}</TableCell>
                    {item.method == "Culture Test Result" ?
                      <TableCell sx={{width:'20vw'}}>
                        -
                      </TableCell> :
                      item?.method === "Lesser and Greater Result" ?
                        <TableCell sx={{width:'20vw'}}>
                          {((item?.normal_range?.length > 0) && Array.isArray(item?.normal_range)) ? item?.normal_range?.map((range, rangeIndex) => (
                            <Table key={rangeIndex}>
                              <TableCell sx={{ padding: '0vw', borderBottom: 'none' }}>
                                {(range?.reference_type + range?.reference_range + range?.unit)?.length > 18 ?
                                  <Tooltip placement='top' title={`${range?.reference_type ? range?.reference_type : ''}  ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}>
                                    <Typography component={'div'} fontSize={"0.8rem"}>{`${range?.reference_type ? range?.reference_type : ''}  ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}</Typography>
                                  </Tooltip> :
                                  <Typography fontSize={"0.8rem"}>{`${range?.reference_type ? range?.reference_type : ''} ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}</Typography>}
                              </TableCell>
                            </Table>
                          )) : '-'}
                        </TableCell> :
                        <TableCell sx={{width:'20vw'}}>
                          {((item?.normal_range?.length > 0) && Array.isArray(item?.normal_range)) ? item?.normal_range?.map((range, rangeIndex) => (
                            <Table key={rangeIndex}>
                              <TableCell sx={{ padding: '0vw', borderBottom: 'none' }}>
                                {(range?.reference_from + range?.reference_to + range?.unit)?.length > 18 ?
                                  <Tooltip placement='top' title={`${range?.reference_from ? range?.reference_from : ''} (${range?.unit ? range?.unit : ''}) to ${range?.reference_to ? range?.reference_to : ''} (${range?.unit ? range?.unit : ''})`}>
                                    <Typography fontSize={"0.8rem"} component={'div'}>{`${range?.reference_from ? range?.reference_from : ''} (${range?.unit ? range?.unit : ''}) to ${range?.reference_to ? range?.reference_to : ''} (${range?.unit ? range?.unit : ''})`}</Typography>
                                  </Tooltip> :
                                  <Typography fontSize={"0.8rem"}>{`${range?.reference_from ? range?.reference_from : ''} ${range?.unit ? "(" + range?.unit + ")" : ''} ${range?.unit ? "to" : "-"} ${range?.reference_to ? range?.reference_to : ''} ${range?.unit ? "(" + range?.unit + ")" : ''}`}</Typography>}
                              </TableCell>
                            </Table>
                          )) : '-'}
                        </TableCell>}
                    <TableCell sx={{width:'20vw'}}> {(item?.is_outsource) ? "Outsourse Test" : (item.is_culture) ? this.culturTestView(item) : item.result?.value ? item.result?.value : '-'}</TableCell>
                    <TableCell sx={{width:'18vw'}}>{item.tested_on ? formatDateAndTimeMeth2(item.tested_on) : '-'}</TableCell>
                    <TableCell sx={{width:'15vw'}}>{item.trun_over_time ? item.trun_over_time : '-'}</TableCell>
                    <TableCell sx={{width:'2vw'}}>
                      <IconButton disabled={item?.from_previous_data ? false : true} onClick={() => {
                        states?.FilteredTestData.splice(index, 1)
                        states.testgetData = states.testgetData?.filter((list)=>(list.id !== item.id))
                        this.setState({
                          states,
                        })
                      }}>
                        {<img className='eMed_DisSum_Lay_TblDel' src={item?.from_previous_data ? ImagePaths.Delete.default : ImagePaths.DisabledDelete.default} alt='Delete' />}
                      </IconButton>
                    </TableCell>
                    {/* <TableCell width={'15vw'}>
                      <Typography onClick = {() => this.getResultPrint(item)}  sx={{ color: Colors.Primary, cursor: "pointer" }}>{"View"}</Typography>
                    </TableCell> */}
                  </TableRow>
                </TableBody>
              )) : null}
            </Table>
            {states?.FilteredTestData?.length === 0 ?
              <Box className='eMed_Dis_Template_NoRecord'>
                <Typography >{t('NoRecordsFound')}</Typography>
              </Box> : null}
          </TableContainer>
        </Paper>
      </Box>
    )
  }

  renderVitalsTbl = () => {
    const { t } = this.props;
    const states = this.state;
    // const obgynVitals = (this.state.selectSpeciality?.name === "OBSTETRICS & GYNECOLOGY" || this.state.selectSpeciality === "OBSTETRICS & GYNECOLOGY")
    return (
      <Box className='eMed_DisSum_Lay_CommonBox1'>
        <Typography>{"Vitals"}</Typography>
        <TableContainer component={Paper} sx={{ height: '40dvh', overflow: 'auto' }}>
          <Table sx={{ minWidth: '30dvw' }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
              <TableRow>
                <TableCell>{"Date"}</TableCell>
                <TableCell>{"Height (cm)"}</TableCell>
                <TableCell>{"Weight (kg)"}</TableCell>
                <TableCell>{"BP (mm/Hg)"}</TableCell>
                <TableCell>{"Temp"}</TableCell>
                <TableCell>{"SpO2"}</TableCell>
                <TableCell>{"PulseRate/min"}</TableCell>
                <TableCell>{"Blood Glu (mg/DL)"}</TableCell>
                {/* {obgynVitals && <TableCell>{"Last Menstrual Period"}</TableCell>} */}
                <TableCell>{"Comments"}</TableCell>
                <TableCell>{"Updated By"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {states.vitalsTblData?.length > 0 ?
                states.vitalsTblData?.map((item) => (
                  <TableRow key={item?.id}>
                    <TableCell>{formatDateAndTime(item?.vital_entered_time)}</TableCell>
                    <TableCell>{item?.height_cm || "-"}</TableCell>
                    <TableCell>{item?.weight_kg || "-"}</TableCell>
                    <TableCell>{item?.blood_pressure_systolic ? `${item?.blood_pressure_systolic}/${item?.blood_pressure_diastolic}` : "-"}</TableCell>
                    <TableCell>{item?.temperature ? `${item?.temperature}  ${item?.temperature_unit}` : "-"}</TableCell>
                    <TableCell>{item?.pulse_oximetry || "-"}</TableCell>
                    <TableCell>{item?.pulse_rate || "-"}</TableCell>
                    <TableCell>{item?.blood_sugar || "-"}</TableCell>
                    {/* {obgynVitals && <TableCell>{formatDate(item?.last_menstrual_period)}</TableCell>} */}
                    <TableCell>{item?.comments?.length > 20 ?
                      <Tooltip arrow title={item?.comments}>
                        <div>{item?.comments.slice(0, 20) + "..."}</div>
                      </Tooltip> : item?.comments || "-"}</TableCell>

                    <TableCell>{item?.nurse_name?.length > 20 ?
                      <Tooltip arrow title={item?.nurse_name}>
                        <div>{item?.nurse_name.slice(0, 20) + "..."}</div>
                      </Tooltip> : item?.nurse_name || "-"}</TableCell>
                  </TableRow>
                )) : <TableRow>
                  <TableCell align="center" sx={{ border: "none", height: "100%" }} colSpan={11}>{t('NoRecordsFound')}</TableCell>
                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  renderRadReportTbl = () => {
    const { t } = this.props;
    const states = this.state;
    return (
      <Box className='eMed_DisSum_Lay_CommonBox1'>
        <Typography>{"Radiology Report"}</Typography>
        <TableContainer component={Paper} sx={{ height: '40dvh', overflow: 'auto' }}>
          <Table sx={{ minWidth: '30dvw' }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
              <TableRow>
                <TableCell>{"S.No"}</TableCell>
                <TableCell>{"Service Type"}</TableCell>
                <TableCell>{"Service Name"}</TableCell>
                <TableCell>{"Result Status"}</TableCell>
                <TableCell>{"Action"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {states.radTblData?.length > 0 ?
                states.radTblData?.map((item, index) => (
                  <TableRow key={item?.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item?.service_type?.length > 35 ?
                      <Tooltip arrow title={item?.service_type}>
                        <div>{item?.service_type.slice(0, 35) + "..."}</div>
                      </Tooltip> : item?.service_type || "-"}</TableCell>

                    <TableCell>{item?.service_name?.length > 35 ?
                      <Tooltip arrow title={item?.service_name}>
                        <div>{item?.service_name.slice(0, 35) + "..."}</div>
                      </Tooltip> : item?.service_name || "-"}</TableCell>

                    <TableCell>{item?.result_status?.length > 35 ?
                      <Tooltip arrow title={item?.result_status}>
                        <div>{item?.result_status.slice(0, 35) + "..."}</div>
                      </Tooltip> : item?.result_status || "-"}</TableCell>
                      
                    <TableCell>{
                      <IconButton onClick={() => {
                        this.setState({ isRadPrint: true }, () => { this.printRadiologyTest(item.id, item.radiology_id) })
                      }}>
                        <Tooltip placement='top' title={'Print'} arrow>
                          <img src={(radPrintEnable?.has(item?.result_status) && !this.state.isRadPrint) ? ImagePaths.PrintIcons.default : ImagePaths.LabPrintIconOff.default}
                            className='eMed_Pat_Bill_img' alt='print' />
                        </Tooltip>
                      </IconButton>}
                    </TableCell>
                  </TableRow>
                )) : <TableRow>
                  <TableCell align="center" sx={{ border: "none", height: "100%" }} colSpan={5}>{t('NoRecordsFound')}</TableCell>
                </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  handleDragStart = (index, event) => {
    event.dataTransfer.setData("text/plain", index);
    this.setState({ dragIndex: index });
  };

  handleDragOver = (index, event) => {
    event.preventDefault();
    const { medicationTbl, dragIndex } = this.state;
    if (dragIndex !== -1 && dragIndex !== index) {
      const draggedItem = medicationTbl[dragIndex];
      const updatedItems = medicationTbl?.filter((item, i) => i !== dragIndex);
      updatedItems.splice(index, 0, draggedItem);
      this.setState({ medicationTbl: updatedItems, dragIndex: index });
    }
  };

  handleDragEnd = () => {
    this.setState({ dragIndex: -1 });
  };

  renderMedication = (mantatory) => {
    const { t, fromBillSummary } = this.props
    var states = this.state

    return (
      <Box className='eMed_DisSum_Lay_CommonBox1'>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: "center" }}>
            <Typography>{mantatory ? 'Medication*' : 'Medication'}</Typography>
            <Button sx={{ textTransform: "capitalize", display: localGetItem("Copy_IP_Mediction_To_DischargeSummery") === "false" ? "none" : "inline" }} onClick={() => { this.CopymedicationFromIP() }}>Copy From IP Medication</Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: "center"}}>
            <FormControl size='small' sx={{ display: this.state.PharmacyList?.length > 1 ? 'inline' : 'none' }}>
              <InputLabel>Select Pharmacy</InputLabel>
              <Select
                label='select Pharmacy'
                value={this.state.selectedPharmacy}
                onChange={(e) => this.setState({ selectedPharmacy: e.target.value })}
              >
                {this.state.PharmacyList?.length > 0 && Array.isArray(this.state.PharmacyList) && this.state.PharmacyList?.map((item, index) => (
                  <MenuItem value={item?.pharmacy_id} key={index}>{item?.pharmacy_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box className='eMed_DisSum_Tem_MediTextBox'>
          {this.medicationTextBox('Medicine Name', states.mediName, 'mediName')}
          {this.medicationTextBox('Dosage Type / Strength', states.mediDosage, 'mediDosage')}
          {this.medicationTextBox('Timing' , states.selectMEAN, 'selectMEAN')}
          {/* <FormControl size='small' sx={{ width: '12vw' }}>
            <InputLabel id="demo-simple-selet-label">M-A-E-N</InputLabel>
            <Select
              labelId="demo-simple-selet-label"
              readOnly={(states.isFinish === true || fromBillSummary) ? true : false}
              id="demo-simple-select"
              value={this.state.selectMEAN}
              label="M-A-E-N"
              onChange={(event) => {
                this.setState({
                  selectMEAN: event.target.value
                })
              }}
            >
              <MenuItem value={'Morning'}>Morning</MenuItem>
              <MenuItem value={'Afternoon'}>Afternoon</MenuItem>
              <MenuItem value={'Evening'}>Evening</MenuItem>
              <MenuItem value={'Night'}>Night</MenuItem>
            </Select>
          </FormControl>
          <FormControl size='small' sx={{ width: '12vw' }}>
            <InputLabel id="demo-simple-label">Consumptions</InputLabel>
            <Select
              labelId="demo-simple-label"
              id="demo-simple-select"
              readOnly={(states.isFinish === true || fromBillSummary) ? true : false}
              value={this.state.selectConsumptions}
              label="Consumables"
              onChange={(event) => {
                this.setState({
                  selectConsumptions: event.target.value
                })
              }}
            >
              <MenuItem value={'Empty Stomach'}>Empty Stomach</MenuItem>
              <MenuItem value={'Before Food'}>Before Food</MenuItem>
              <MenuItem value={'With Food'}>With Food</MenuItem>
              <MenuItem value={'After Food'}>After Food</MenuItem>
              <MenuItem value={'External Use'}>External Use</MenuItem>
            </Select>
          </FormControl> */}
          {this.medicationTextBox('Route', states.mediRoute, 'mediRoute')}
          {this.medicationTextBox('Instructions', states.mediInstruction, 'mediInstruction')}
          <Box>
            <Tooltip title='Add' placement='top' arrow>
              <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => { this.addMedication() }}>
                {<img id='eMed_activeBtn' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledAddIcon.default : ImagePaths.AddIcon.default} alt='Add' />}
              </IconButton>
            </Tooltip>
            <Tooltip title='Clear' placement='top' arrow>
              <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => { this.clearMedication() }}>
                {<img id='eMed_activeBtn' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledClearIcon.default : ImagePaths.ClearIcon.default} alt='Clear' />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Paper elevation={0}>
          <TableContainer sx={{ minHeight: '23vh', marginTop: '1vw' }} component={Paper}>
            <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                <TableRow>
                  <TableCell width={'5vw'}>S.No</TableCell>
                  <TableCell width={'18vw'}>Medicine Name</TableCell>
                  <TableCell width={'18vw'}>Dosage Type / Strength</TableCell>
                  <TableCell width={'18vw'}>Timing</TableCell>
                  <TableCell width={'18vw'}>Route</TableCell>
                  <TableCell width={'18vw'}>Instruction</TableCell>
                  <TableCell width={'5vw'}>Action</TableCell>
                </TableRow>
              </TableHead>
              {states.medicationTbl?.length > 0 ? Array.isArray(states.medicationTbl) && states.medicationTbl.map((item, index) => (
                <TableBody>
                  <TableRow
                    draggable
                    onDragStart={(event) => this.handleDragStart(index, event)}
                    onDragOver={(event) => this.handleDragOver(index, event)}
                    onDragEnd={this.handleDragEnd}
                  >
                    <TableCell width={'5vw'}>
                      <img style={{width:'0.9vw', height:"0.9vw", marginRight:'0.5vw'}} src={ImagePaths.DragIndicator.default} alt="Dot" />
                      {index + 1}
                      </TableCell>
                    <TableCell width={'18vw'}>
                      {item?.medicinename?.length > 15 ?
                        <Tooltip title={item.medicinename} placement='top' arrow>
                          <Typography>{item.medicinename.slice(0, 15) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.medicinename}</Typography>}
                    </TableCell>
                    <TableCell width={'18vw'}>
                      {item?.dosage?.length > 15 ?
                        <Tooltip title={item.dosage} placement='top' arrow>
                          <Typography>{item.dosage.slice(0, 15) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.dosage}</Typography>}
                    </TableCell>
                    <TableCell width={'18vw'}>
                      {item?.maen?.length > 15 ?
                        <Tooltip title={item.maen} placement='top' arrow>
                          <Typography>{item.maen.slice(0, 15) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.maen}</Typography>}
                    </TableCell>
                    <TableCell width={'18vw'}>
                      {item?.route?.length > 15 ?
                        <Tooltip title={item.route} placement='top' arrow>
                          <Typography>{item.route.slice(0, 15) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.route}</Typography>}
                    </TableCell>
                    <TableCell width={'18vw'}>
                      {item?.instruction?.length > 20 ?
                        <Tooltip title={item.instruction} placement='top' arrow>
                          <Typography>{item.instruction.slice(0, 20) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.instruction}</Typography>}
                    </TableCell>
                    <TableCell width={'5vw'}>
                      <Box className='eMed_Dis_Tem_ActionBtn'>
                        <Tooltip title={'Eidt'} placement='top' arrow>
                          <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => { this.editMedication(item, index) }}>
                            {<img className='eMed_DisSum_Lay_TblDel' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledEdit.default : ImagePaths.Edit.default} alt='Edit' />}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Delete'} placement='top' arrow>
                          <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => {
                            states.medicationTbl.splice(index, 1)
                            this.setState({
                              states,
                              mediRoute: '',
                              mediName: '',
                              mediDosage: '',
                              selectMEAN: '',
                              selectConsumptions: '',
                              mediInstruction: '',
                            })
                          }}>
                            {<img className='eMed_DisSum_Lay_TblDel' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} alt='Delete' />}
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )) : null}
            </Table>
            {states.medicationTbl?.length === 0 ?
              <Box className='eMed_Dis_Template_NoRecord'>
                <Typography >{t('NoRecordsFound')}</Typography>
              </Box> : null}
          </TableContainer>
        </Paper>
      </Box>
    )
  }

  renderinHospMedication = (mantatory) => {
    const { t, fromBillSummary } = this.props
    var states = this.state
    return (
      <Box className='eMed_DisSum_Lay_CommonBox1'>
        <Box sx={{ display: 'flex', alignItems: "center" }}>
          <Typography>{mantatory ? 'In Hospital Medication*' : 'In Hospital Medication'}</Typography>
          <Tooltip placement='top' title='Copy from Drug Chart'>
            <IconButton onClick={() => { this.getIpDrugChartDetails() }}>
              <ContentCopy color={"primary"} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box className='eMed_DisSum_Tem_MediTextBox'>
          {this.medicationTextBox('Medicine Name', states.inHospmediName, 'inHospmediName')}
          {this.medicationTextBox('Dosage Type / Strength', states.inHospmediDosage, 'inHospmediDosage')}
          {this.medicationTextBox('Timing' , states.inHospselectMEAN, 'inHospselectMEAN')}
          {this.medicationTextBox('Route', states.inHospmediRoute, 'inHospmediRoute')}
          {this.medicationTextBox('Instructions', states.inHospmediInstruction, 'inHospmediInstruction')}
          <Box>
            <Tooltip title='Add' placement='top' arrow>
              <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => { this.InHospAddMedication() }}>
                {<img id='eMed_activeBtn' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledAddIcon.default : ImagePaths.AddIcon.default} alt='Add' />}
              </IconButton>
            </Tooltip>
            <Tooltip title='Clear' placement='top' arrow>
              <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => { this.clearMedication(true) }}>
                {<img id='eMed_activeBtn' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledClearIcon.default : ImagePaths.ClearIcon.default} alt='Clear' />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Paper elevation={0}>
          <TableContainer sx={{ minHeight: '23vh', marginTop: '1vw' }} component={Paper}>
            <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                <TableRow>
                  <TableCell width={'5vw'}>S.No</TableCell>
                  <TableCell width={'18vw'}>Medicine Name</TableCell>
                  <TableCell width={'18vw'}>Dosage Type / Strength</TableCell>
                  <TableCell width={'18vw'}>Timing</TableCell>
                  <TableCell width={'18vw'}>Route</TableCell>
                  <TableCell width={'18vw'}>Instruction</TableCell>
                  <TableCell width={'5vw'}>Action</TableCell>
                </TableRow>
              </TableHead>
              {/* need */}
              {states.inHospmedicationTbl?.length > 0 ? Array.isArray(states.inHospmedicationTbl) && states.inHospmedicationTbl.map((item, index) => (
                <TableBody>
                  <TableRow>
                    <TableCell width={'5vw'}>
                      {/* <img style={{width:'0.9vw', height:"0.9vw", marginRight:'0.5vw'}} src={ImagePaths.DragIndicator.default} alt="Dot" /> */}
                      {index + 1}
                      </TableCell>
                    <TableCell width={'18vw'}>
                      {item?.medicinename?.length > 15 ?
                        <Tooltip title={item.medicinename} placement='top' arrow>
                          <Typography>{item.medicinename.slice(0, 15) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.medicinename}</Typography>}
                    </TableCell>
                    <TableCell width={'18vw'}>
                      {item?.dosage?.length > 15 ?
                        <Tooltip title={item.dosage} placement='top' arrow>
                          <Typography>{item.dosage.slice(0, 15) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.dosage}</Typography>}
                    </TableCell>
                    {/* <TableCell width={'18vw'}>{item.maen}</TableCell> */}
                    <TableCell width={'18vw'}>
                      {item?.maen?.length > 15 ?
                        <Tooltip title={item.maen} placement='top' arrow>
                          <Typography>{item.maen.slice(0, 15) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.maen}</Typography>}
                    </TableCell>
                    <TableCell width={'18vw'}>
                      {item?.route?.length > 15 ?
                        <Tooltip title={item.route} placement='top' arrow>
                          <Typography>{item.route.slice(0, 15) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.route}</Typography>}
                    </TableCell>
                    <TableCell width={'18vw'}>
                      {item?.instruction?.length > 20 ?
                        <Tooltip title={item.instruction} placement='top' arrow>
                          <Typography>{item.instruction.slice(0, 20) + '...'}</Typography>
                        </Tooltip> : <Typography>{item.instruction}</Typography>}
                    </TableCell>
                    <TableCell width={'5vw'}>
                      <Box className='eMed_Dis_Tem_ActionBtn'>
                        <Tooltip title={'Eidt'} placement='top' arrow>
                          <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => { this.editMedication(item, index, true) }}>
                            {<img className='eMed_DisSum_Lay_TblDel' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledEdit.default : ImagePaths.Edit.default} alt='Edit' />}
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={'Delete'} placement='top' arrow>
                          <IconButton disabled={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? true : false} onClick={() => {
                            states.inHospmedicationTbl.splice(index, 1)
                            this.setState({
                              states,
                              inHospmediRoute: '',
                              inHospmediName: '',
                              inHospmediDosage: '',
                              inHospselectMEAN: '',
                              inHospselectConsumptions: '',
                              inHospmediInstruction: '',
                            })
                          }}>
                            {<img className='eMed_DisSum_Lay_TblDel' src={(states.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip) ? ImagePaths.DisabledDelete.default : ImagePaths.Delete.default} alt='Delete' />}
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )) : null}
            </Table>
            {states.inHospmedicationTbl?.length === 0 ?
              <Box className='eMed_Dis_Template_NoRecord'>
                <Typography >{t('NoRecordsFound')}</Typography>
              </Box> : null}
          </TableContainer>
        </Paper>
      </Box>
    )
  }

  duplicateCheck = () => {
    var { mediRoute, mediName, mediDosage, selectMEAN, selectConsumptions, mediInstruction, medicationTbl } = this.state
    var duplicate = ''
    if(!(mediRoute.trim()) || !(mediName.trim()) || !(mediDosage.trim()) || !(selectMEAN.trim())){
      duplicate = "Kindly Enter the Mandatory Fields"
    }else if (mediRoute || mediName || mediDosage || selectMEAN || mediInstruction) {
      for (let i = 0; i < medicationTbl.length; i++) {
        if (medicationTbl[i].route == mediRoute && medicationTbl[i].medicinename == mediName && medicationTbl[i].dosage == mediDosage && medicationTbl[i].maen == selectMEAN &&
           medicationTbl[i].instruction == mediInstruction) {
            duplicate = "Already Value Added"
        }
      }
    }
    return duplicate
  }

  inHospduplicateCheck = () => {
    var { inHospmediRoute, inHospmediName, inHospmediDosage, inHospselectMEAN, selectConsumptions, inHospmediInstruction, inHospmedicationTbl } = this.state
    var duplicate = ''
    if(!(inHospmediRoute.trim()) || !(inHospmediName.trim()) || !(inHospmediDosage.trim()) || !(inHospselectMEAN.trim())){
      duplicate = "Kindly Enter the Mandatory Fields"
    }else if (inHospmediRoute || inHospmediName || inHospmediDosage || inHospselectMEAN || inHospmediInstruction) {
      for (let i = 0; i < inHospmedicationTbl.length; i++) {
        if (inHospmedicationTbl[i].route == inHospmediRoute && inHospmedicationTbl[i].medicinename == inHospmediName && inHospmedicationTbl[i].dosage == inHospmediDosage && inHospmedicationTbl[i].maen == inHospselectMEAN &&
           inHospmedicationTbl[i].instruction == inHospmediInstruction) {
            duplicate = "Already Value Added"
        }
      }
    }
    return duplicate
  }
  addMedication = () => {
    var states = this.state
    var duplicate = this.duplicateCheck()
    var data = {
      'route': states.mediRoute.trim(),
      'medicinename': states.mediName.trim(),
      'dosage': states.mediDosage.trim(),
      'maen': states.selectMEAN,
      // 'consumption': states.selectConsumptions,
      'instruction': states.mediInstruction.trim()
    }
    if (states.selectedIndex != null && !duplicate) {
      states.medicationTbl[states.selectedIndex] = data
      this.setState({
        states
      }, () => { this.clearMedication() })
    } else if (duplicate) {
      this.errorMessage(duplicate)
    } else {
      if (states.mediRoute &&
        states.mediName &&
        states.mediDosage &&
        states.selectMEAN ) {

        states['medicationTbl'].push(data)
        this.setState({
          states
        }, () => { this.clearMedication() })
      } else {
        this.errorMessage('Please Enter All the Feilds')
      }
    }
  }

  InHospAddMedication = () => {
    var states = this.state
    var duplicate = this.inHospduplicateCheck(true)
    var data = {
      'route': states.inHospmediRoute.trim(),
      'medicinename': states.inHospmediName.trim(),
      'dosage': states.inHospmediDosage.trim(),
      'maen': states.inHospselectMEAN,
      // 'consumption': states.selectConsumptions,
      'instruction': states.inHospmediInstruction.trim()
    }
    if (states.inHospselectedIndex != null && !duplicate) {
      states.inHospmedicationTbl[states.inHospselectedIndex] = data
      this.setState({
        states
      }, () => { this.clearMedication(true) })
    } else if (duplicate) {
      this.errorMessage(duplicate)
    } else {
      if (states.inHospmediRoute &&
        states.inHospmediName &&
        states.inHospmediDosage &&
        states.inHospselectMEAN ) {

        states['inHospmedicationTbl'].push(data)
        this.setState({
          states
        }, () => { this.clearMedication(true) })
      } else {
        this.errorMessage('Please Enter All the Feilds')
      }
    }
  }
  editMedication = (item, index, inHosp = false) => {
    if(inHosp){
      this.setState({
        inHospmediRoute: item.route,
        inHospmediName: item.medicinename,
        inHospmediDosage: item.dosage,
        inHospselectMEAN: item.maen,
        inHospselectConsumptions: item.consumption,
        inHospmediInstruction: item.instruction,
        inHospselectedIndex: index
      })
    }else{
      this.setState({
        mediRoute: item.route,
        mediName: item.medicinename,
        mediDosage: item.dosage,
        selectMEAN: item.maen,
        selectConsumptions: item.consumption,
        mediInstruction: item.instruction,
        selectedIndex: index
      })
    }

  }
  clearMedication = (FromInHospMedication = false) => {
    if(FromInHospMedication){
      this.setState({
        inHospmediRoute: '',
        inHospmediName: '',
        inHospmediDosage: '',
        inHospselectMEAN: '',
        inHospselectConsumptions: '',
        inHospmediInstruction: '',
        inHospselectedIndex: null,
      })
    }else{
      this.setState({
        mediRoute: '',
        mediName: '',
        mediDosage: '',
        selectMEAN: '',
        selectConsumptions: '',
        mediInstruction: '',
        selectedIndex: null,
        brandnameList : []
      })
    }
  }

  _getBrandList = (brand_name) => {
    const { t } = this.props;
    this.setState({
      brandnameList: []
    })
    if (brand_name) {
      try {
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser
        RestAPIService.getAll(Serviceurls.PHARMA_BILLING_BRAND_SEARCH + `?search=${encodeURIComponent(brand_name)}&pharmacy_id=${this.state.selectedPharmacy}` +  (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : ""))
          .then(response => {
            if (response.data.status === "success") {
              this.setState({
                brandnameList: response.data.data,
              });
            }
          })
          .catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.errorMessage(error.response.data.message);
            }
          });
      } catch (e) {
        this.errorMessage(e.message);
      }
    }
  }

  CopymedicationFromIP = () => {
    try {
      var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
      var UserData = myUser
      let admission_id = this.state.ipPatientData?.id ? this.state.ipPatientData?.id : this.state.withOutAppointment?.ip_admission_id ? this.state.withOutAppointment?.ip_admission_id : this.state.ipPatientData?.ip_admission_id ? this.state.ipPatientData?.ip_admission_id : null;
      RestAPIService.getAll(`${Serviceurls.IP_MEDICATION_GET}?ip_admission_id=${admission_id}${ (this.props?.isFromDoctor ? `&clinic_id=${getCachevalue('SelectedDoctorClinic')}` : "")}`)
        .then(response => {
          if (response.data.status === "success") {
            if (response.data.status === "success" && response.data?.data.length > 0) {
              let LineItems = []
              response.data?.data.forEach((list)=>( LineItems = [...LineItems, ...list?.line_items ? list?.line_items : []]))
              let MedicationList = []
              LineItems.forEach((list, index) => {
                let NewObj = {
                  "route": list?.route ? list?.route : "",
                  "medicinename": `${list?.brand_name ? list?.brand_name : ""} - ${list?.generic_name ? list?.generic_name : ""}`,
                  "dosage": `${list?.dosag_type ? list?.dosag_type : ""} / ${list?.drug_strength ? list?.drug_strength : ""}`,
                  "maen": `${list?.morning ? list?.morning : "0"} - ${list?.afternoon ? list?.afternoon : "0"}  - ${list?.evening ? list?.evening : "0"}  - ${list?.night ? list?.night : "0"}`,
                  "instruction": list?.instructions ? list?.instructions : ""
                }
                if(this.state.medicationTbl.some((item, index)=>((item.medicinename === NewObj.medicinename && item.route === NewObj.route && item.dosage === NewObj.dosage && item.maen === NewObj.maen && item.instruction === NewObj.instruction)))){
                  this.errorMessage(`Durg Already added`)
                }else{
                  MedicationList.push(NewObj)
                }
              })
              this.setState({
                medicationTbl: [...this.state.medicationTbl, ...MedicationList]
              })
            } else {
              this.errorMessage("Medication is not added in IP")
            }

          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message);
          }
        });
    } catch (e) {
      this.errorMessage(e.message);
    }
  }
  
  medicationTextBox = (label, value, name) => {
    var states = this.state
    let { fromBillSummary } = this.props;
    return (
      <Box >
        {
          name === "mediName" ?
            <Autocomplete
              disableClearable
              value={value ? value : ""}
              sx={{ width: "20vw" }}
              size="small"
              options={states.brandnameList.length != 0 ? states["brandnameList"] : []}
              getOptionLabel={option => typeof (option) === "string" ? option : `${option?.drug_name} - ${option?.generic_name}`}
              onChange={(event, newbrand) => {
                states["mediName"] = `${newbrand?.drug_name} - ${newbrand?.generic_name}`;
                states["mediDosage"] = `${newbrand?.dosage_type ? newbrand?.dosage_type : ""} / ${newbrand?.dosage_strength ? newbrand?.dosage_strength : ""}`;
                this.setState({
                  states
                })
              }}
              onInputChange={(event, newBrand) => {
                if (newBrand.length > 2) {
                  this._getBrandList(newBrand);
                } else {
                  this.setState({ brandnameList: [] })
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Medicine Name"}
                  autoComplete="off"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  onChange={(event) => {
                    states["mediName"] = event.target.value
                    this.setState({
                      states
                    })
                  }}
                />
              )}
            />
            : name === "mediRoute" ?
              <Autocomplete
                freeSolo
                disableClearable
                value={value ? value : ""}
                sx={{ width: "15vw" }}
                size="small"
                options={(IP_routeList.length != 0) ? IP_routeList : []}
                getOptionLabel={option => typeof (option) === "string" ? option : option.value}
                onChange={(event, newRoute) => {
                  states["mediRoute"] = newRoute.value
                  this.setState({
                    states
                  })
                }}
                onInputChange={(e,value) => {
                  states["mediRoute"] = value
                  this.setState({states})
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    autoComplete="off"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
              : (name === "mediInstruction" || name === "inHospmediInstruction") ?
                <TextField
                  label={label}
                  value={value}
                  name={name}
                  size='small'
                  inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
                  variant='outlined'
                  onChange={(event) => {
                    const name = event.target.name
                    const value = event.target.value
                    if ((value != "" && value?.length <= 250) || value === '') {
                      this.setState({
                        [name]: value
                      })
                    }
                  }}
                />
                :
              <TextField
                label={label}
                value={value}
                name={name}
                size='small'
                inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
                variant='outlined'
                onChange={(event) => {
                  const name = event.target.name
                  const value = event.target.value
                  if (value != "" || value === '') {
                    this.setState({
                      [name]: value
                    })
                  }
                }}
              />}
      </Box>
    )
  }

  renderDisInstruction = (mantatory) => {
    const { t, fromBillSummary } = this.props
    var states = this.state
    let disable = states.isFinish === true || fromBillSummary ? true : false  
    return (
      <Box className='eMed_DisSum_Lay_disBox' height={'auto'}>
        <Box className='eMed_DisSum_Lay_CommonBox'>
          <Typography>{mantatory ? 'Discharge Instruction*' : 'Discharge Instruction'}</Typography>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "flex-start", gap: "2vw", marginBottom: "2vw"}}>
            {/* <FormControl size='small' className='eMed_DisSum_Template_Diet'>
              <InputLabel id="Dis_lay_Diet">{t('Diet')}</InputLabel>
              <Select
                labelId="Dis_lay_Diet"
                id="Dis_lay_Diet"
                readOnly={states.isFinish === true || fromBillSummary ? true : false}
                value={this.state.selectDiet}
                label={t('Diet')}
                onChange={(event) => {
                  this.setState({
                    selectDiet: event.target.value
                  })
                }}
              >
                <MenuItem value={1}>Fruit</MenuItem>
                <MenuItem value={2}>Milk</MenuItem>
              </Select>
            </FormControl>
            <FormControl size='small' className='eMed_DisSum_Template_Diet'>
              <InputLabel id="Dis_lay_Diet">{t('Activity')}</InputLabel>
              <Select
                labelId="Dis_lay_Diet"
                id="Dis_lay_Diet"
                readOnly={states.isFinish === true || fromBillSummary ? true : false}
                value={this.state.selectActivity}
                label={t('Activity')}
                onChange={(event) => {
                  this.setState({
                    selectActivity: event.target.value
                  })
                }}
              >
                <MenuItem value={1}>Walking</MenuItem>
                <MenuItem value={2}>Running</MenuItem>
              </Select>
            </FormControl> */}

            {/* <TextField className={this.props.fromBillSummary ? 'eMed_Discharge_txtbox' : 'eMed_DisSum_Template_Diet'}
              autoComplete="off"
              size="small"
              label={t('Diet')}
              value={this.state.selectDiet}
              inputProps={
                { readOnly: (states.isFinish === true || fromBillSummary) ? true : false }
              }
              onChange={(event) => {
                this.setState({
                  selectDiet: event.target.value
                })
              }}
              InputProps={{
                endAdornment:<InputAdornment position="end">
                   <MicIcon  sx={{ color:  grey[1000] ,cursor:"pointer" }} onClick={()=>{this.setState({listenPopUp:true, ClickKey : "Diet" }) }}></MicIcon>
                 </InputAdornment>
              }}
            /> */}
            <div style={{width:"48%"}}>
                <ToolsFieldWithMic
                  Title={t('Diet')} 
                  EditorRef={this.state.selectDiet ? this.state.selectDiet : ''} 
                  EditorDisable={disable}
                  saveData={this.setEditedData.bind(this)} 
                  isFrom="dischargeSummary"
                  minHeight={"12vh"}
                  overallHeight={"auto"}
                  stateName='selectDiet'  
              />
            </div>

            {/* <TextField className={this.props.fromBillSummary ? 'eMed_Discharge_txtbox' : 'eMed_DisSum_Template_Diet'}
              autoComplete="off"
              size="small"
              label={t('Activity')}
              value={this.state.selectActivity}
              inputProps={
                { readOnly: (states.isFinish === true || fromBillSummary) ? true : false }
              }
              onChange={(event) => {
                this.setState({
                  selectActivity: event.target.value
                })
              }}
              InputProps={{
                endAdornment:<InputAdornment position="end">
                   <MicIcon  sx={{ color:  grey[1000] ,cursor:"pointer" }} onClick={()=>{this.setState({listenPopUp:true, ClickKey : "Activity" }) }}></MicIcon >
                 </InputAdornment>
              }}
            /> */}
              <div style={{width:"48%"}}>
                <ToolsFieldWithMic
                  Title={t('Activity')} 
                  EditorRef={this.state.selectActivity ? this.state.selectActivity : ''} 
                  EditorDisable={disable}
                  saveData={this.setEditedData.bind(this)} 
                  isFrom="dischargeSummary"
                  minHeight={"12vh"}
                  overallHeight={"auto"}
                  stateName='selectActivity'  
                />
              </div>
          </Box>
          {/* <TextField
            sx={{ width: this.props.fromBillSummary ? '84.5vw' : '87.3vw', marginTop: '1vw' }}
            id="icd-code"
            multiline
            inputProps={{ readOnly: (states.isFinish === true || fromBillSummary) ? true : false }}
            // rows={3}
            value={this.state.adviceDischarge}
            label={t('AdviceonDischarge*')}
            variant="outlined"
            onChange={(event) => {
              this.setState({
                adviceDischarge: event.target.value
              })
            }}
            InputProps={{
              endAdornment:<InputAdornment position="end">
                 <MicIcon  sx={{ color:  grey[1000] ,cursor:"pointer" }} onClick={()=>{this.setState({listenPopUp:true,ClickKey:"AdviceonDischarge*"})}}></ MicIcon>
               </InputAdornment>
            }}
          /> */}
            <ToolsFieldWithMic
              Title={t('AdviceonDischarge*')} 
              EditorRef={this.state.adviceDischarge ? this.state.adviceDischarge : ''} 
              EditorDisable={disable}
              saveData={this.setEditedData.bind(this)} 
              isFrom="dischargeSummary"
              minHeight={"12vh"}
              overallHeight={"auto"}
              stateName='adviceDischarge'  
          />
        </Box>
      </Box>
    )
  }
  saveTemplate = (isfinished) => {
    try {
      var states = this.state
      var data = states.disLayoutDesign?.filter((item) => (item.id == states.selectLayout))
      var isAdmissionDiagnosis = false
      var isMedication = false
      var isInvestigation = false
      var isExamination = false
      var inHospMedication = false
      data[0].dis_sum_json.find((item) => { 
        if(item.label === "Admission Diagnosis" && item.is_active) {
          isAdmissionDiagnosis = true
        } else if( item.label === "Investigation and Test Report" && item.is_active) {
           isInvestigation = true
        } else if(item.label === "Medication" && item.is_active) {
          isMedication = true
        } else if(item.label === "Examination" && item.is_active) {
          isExamination = true
        } else if(item.label === "In Hospital Medication" && item.is_active) {
          inHospMedication = true
        }
      })
      let TestDataArray = []
      if (states?.FilteredTestData.length > 0 && isInvestigation) {
        states?.FilteredTestData.forEach((list, index) => {
          if (list?.result_completed_date && !list?.result_completed_date.includes(".")) {
            list.result_completed_date = `${list.result_completed_date}.123456`
          }
          if (list?.specimen_collected_date && !list?.specimen_collected_date.includes(".")) {
            list.specimen_collected_date = `${list.specimen_collected_date}.123456`
          }
          if (list?.tested_on && !list?.tested_on.includes(".")) {
            list.tested_on = `${list.tested_on}.123456`
          }
          TestDataArray.push(list)
        })
      }
      states.postDisSumData.admission_diagnosis = states.icdADTblData?.length > 0 && isAdmissionDiagnosis ? states.icdADTblData : ""
      states.postDisSumData["provisional_diagnosis"] = (isAdmissionDiagnosis && states?.selectAdmissionProvisional?.length > 0) ? states.selectAdmissionProvisional : ""
      states.postDisSumData.discharge_diagnosis = states.icdDDTblData?.length > 0 ? states.icdDDTblData : ""
      states.postDisSumData["discharge_provisional_diagnosis"] = ( states?.selectDischargeProvisional?.length > 0) ? states.selectDischargeProvisional : ""
      states.postDisSumData.medication = states.medicationTbl?.length > 0 && isMedication ? states.medicationTbl : ""
      states.postDisSumData.in_hospital_medication = states.inHospmedicationTbl?.length > 0 && inHospMedication ? states.inHospmedicationTbl : ""
      states.postDisSumData.examination = states?.postDisSumData?.examination?.length > 0 && isExamination ? states.postDisSumData.examination : ""
      states.postDisSumData.discharge_instruction = (states.selectDiet || states.selectActivity) || states.adviceDischarge ? {
        "diet": states.selectDiet,
        "acivity": states.selectActivity,
        "advice_discharge": states.adviceDischarge
      } : ""
      states.postDisSumData.name_anaesthetist_and_type_of_anaesthesia = states.typeAnaesthesia && states.nameAnaesthesia ? {
        "type_of_anaesthesia": states.typeAnaesthesia,
        "name_of_anaesthetist": states.nameAnaesthesia
      } : ""
      states.postDisSumData.investigation_and_test_report =  {
        "investigation": states.invetigation ? states.invetigation : "",
        "test_report": TestDataArray ? TestDataArray : []
      }
      states.postDisSumData.vitals = states.vitalsTblData?.length > 0 ? states.vitalsTblData : [];
      states.postDisSumData.radiology_report = states.radTblData?.length > 0 ? states.radTblData : []; 
      states.disSumdata = states.postDisSumData ? states.postDisSumData : {}
      this.setState({
        states
      }, () =>{
        if (this.mantatoryFeilds()) {
          if (isfinished === true) {
            this.setState({
              completePop: true,
              isSave: false,
            })
          } else {
            this.postDischargeSummary(isfinished)
          }
  
        }
      })
    } catch (e) {
      this.setState({isSave: false})
      this.errorMessage(e.message)
    }
  }
  mantatoryFeilds = () => {
    var states = this.state
    var data = states.disLayoutDesign?.filter((item) => (item.id == states.selectLayout))
    var ManFeild = data[0].dis_sum_json
    var flag = true;

    if (states.reasonAdmission === '') {
      flag = false
      this.errorMessage(`Reason for Admission is Mantatory`)
    }else{
      for (let i = 0; i < ManFeild.length; i++) {
        if (ManFeild[i].is_mantatory === true && flag && ManFeild[i].is_active === true) {
          switch(ManFeild[i].label) { 
            case "Admission Diagnosis" :  
             if(ManFeild[i].label == "Admission Diagnosis" && states?.icdADTblData.length === 0 && states.selectAdmissionProvisional === "") {
              flag = false
              this.errorMessage(`${ManFeild[i].label} is Mantatory`)
              break
             }
             break;
            case "Discharge Diagnosis" : 
             if (ManFeild[i].label == "Discharge Diagnosis" && states?.icdDDTblData.length === 0 && states.selectDischargeProvisional === "") {
              flag = false
              this.errorMessage(`${ManFeild[i].label} is Mantatory`)
              break
             }
             break;
            default : 
            if (states.reasonAdmission === '') {
              flag = false
              this.errorMessage(`Reason for Admission is Mantatory`)
            } else if (states.disSumdata[ManFeild[i].field_name] === '') {
              flag = false
              this.errorMessage(`${ManFeild[i].label} is Mantatory`)
            } else if (typeof (states.disSumdata[ManFeild[i].field_name] === 'array') && states.disSumdata[ManFeild[i].field_name]?.length == 0) {
              flag = false
              this.errorMessage(`${ManFeild[i].label} is Mantatory`)
            } else if (typeof (states.disSumdata[ManFeild[i].field_name] === 'object') && (states.disSumdata[ManFeild[i].field_name]?.diet === '' && states.disSumdata[ManFeild[i].field_name]?.acivity === '' && states.disSumdata[ManFeild[i].field_name]?.addDisDignosis === '')) {
              flag = false
              this.errorMessage(`${ManFeild[i].label} is Mantatory`)
            } else {
             flag = true
          }
          }
        }
      } 
    }
    return flag;

  }
  clearTemplateData = () => {
    var states = this.state
    var data = states.disLayoutDesign?.filter((item) => (item.id == states.selectLayout))
    var keys = {}
    data[0]?.dis_sum_json?.forEach((element) => {
      keys[element.field_name] = ' '
      
    })
    this.setState({
      postDisSumData: keys,
      icdADTblData: [],
      icdDDTblData: [],
      selectAdmissionProvisional:' ',
      selectDischargeProvisional:' ',
      invetigation:' ',
      typeAnaesthesia: ' ',
      nameAnaesthesia: ' ',
      medicationTbl: [],
      selectDiet: ' ',
      selectActivity: ' ',
      adviceDischarge: ' ',
      selectSurDate: null,
      disDateTimePicker: null,
      nextDatePicker: null,
      reasonAdmission: ' ',
      invetigation: " ",
      selectDischargeProvisional: ' ',
      selectAdmissionProvisional: ' ',
      inHospmedicationTbl: [],
      
    })
    this.renderDischargeSummary()
    this.popClear()
  }
  popClose = () => {
    this.setState({
      alertPop: false
    })
  }
  popClear = () => {
    this.setState({
      clearPop: false
    })
  }
  CopyPopClose = () => {
    this.setState({
      OpenPrevCopyPop: false
    })
  }

  PasteDisSumData = (CopiedData) => {
    this.setState({
      isLoader: true,
    })
    let states = this.state
    var keys = {}
    CopiedData?.forEach((element) => {
      if (element?.isChecked) {
        keys[element?.key_name] = element?.key_value
      } else {
        keys[element?.key_name] = states.postDisSumData[element?.key_name]
      }
    })
    this.setState({
      postDisSumData: keys,
      icdADTblData: keys.admission_diagnosis ? keys.admission_diagnosis : states.icdADTblData ? states.icdADTblData : [],
      selectAdmissionProvisional: keys.provisional_diagnosis ? keys.provisional_diagnosis : states.selectAdmissionProvisional ? states.selectAdmissionProvisional : "",
      typeAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.type_of_anaesthesia : states.typeAnaesthesia ? states.typeAnaesthesia : '',
      nameAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.name_of_anaesthetist : states.nameAnaesthesia ? states.nameAnaesthesia : '',
      icdDDTblData: keys.discharge_diagnosis ? keys.discharge_diagnosis : states.icdDDTblData ? states.icdDDTblData : [],
      selectDischargeProvisional: keys.discharge_provisional_diagnosis ? keys.discharge_provisional_diagnosis : states.selectDischargeProvisional ? states.selectDischargeProvisional : "",
      medicationTbl: keys.medication ? keys.medication : states.medicationTbl ? states.medicationTbl : [],
      selectDiet: keys.discharge_instruction ? keys.discharge_instruction.diet : states.selectDiet ? states.selectDiet : '',
      selectActivity: keys.discharge_instruction ? keys.discharge_instruction.acivity : states.selectActivity ? states.selectActivity : '',
      adviceDischarge: keys.discharge_instruction ? keys.discharge_instruction.advice_discharge : states.adviceDischarge ? states.adviceDischarge : '',
      invetigation: keys.investigation_and_test_report ? keys.investigation_and_test_report.investigation : states.invetigation ? states.invetigation : '',
      testgetData: keys.investigation_and_test_report ? keys.investigation_and_test_report.test_report : states.testgetData ? states.testgetData : '',
      inHospmedicationTbl: keys.in_hospital_medication ? keys.in_hospital_medication : states.inHospmedicationTbl ? states.inHospmedicationTbl : [],
      OpenPrevCopyPop: false,
      isLoader: false,
    }, () => { this.FilterTestDatas() })
  }
  
  notiPopClose() {
    this.setState({
      notifyPop: false
    })
  }
  confirmSave() {
    this.setState({
      selectLayout: this.state.temLayId,
      alertPop: false
    }, () => {
      this.getTemplateList()
      if (this.state.selectTemplate) {
        this.clearTemplateData()
        this.setState({
          temLayId: null,
          selectTemplate: ''
        })
      } else {
        this.setState({
          temLayId: null,
          selectTemplate: ''
        })
      }
    })
  }
  notiSave() {
    this.clearTemplateData()
    this.setState({
      selectTemplate: this.state.temraryID,
      notifyPop: false
    }, () => {
      var states = this.state
      var tem_data = states.disSumTemData.find((item) => (
        item.id === states.selectTemplate
      ))      
      var data1 = states.disLayoutDesign?.filter((item) => (item.id == states.selectLayout))
      var keys = {}
      data1[0]?.dis_sum_json?.forEach((element) => {
        keys[element.field_name] = tem_data.template_data[element.field_name]
      })
      if(states.isFinish || keys?.investigation_and_test_report?.test_report?.length > 0 ? keys?.investigation_and_test_report?.test_report[0].id == null : null){
      this.setState({
        postDisSumData: keys,
        icdADTblData: keys.admission_diagnosis ? keys.admission_diagnosis : [],
        typeAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.type_of_anaesthesia : '',
        nameAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.name_of_anaesthetist : '',
        icdDDTblData: keys.discharge_diagnosis ? keys.discharge_diagnosis : [],
        medicationTbl: keys.medication ? keys.medication : [],
        inHospmedicationTbl: keys.in_hospital_medication ? keys.in_hospital_medication : [],
        selectDiet: keys.discharge_instruction ? keys.discharge_instruction.diet : '',
        selectActivity: keys.discharge_instruction ? keys.discharge_instruction.acivity : '',
        adviceDischarge: keys.discharge_instruction ? keys.discharge_instruction.advice_discharge : '',
        invetigation: keys.investigation_and_test_report ? keys.investigation_and_test_report.investigation : '',
        testgetData: keys.investigation_and_test_report ? keys.investigation_and_test_report.test_report : '',
        selectDischargeProvisional: tem_data?.template_data?.discharge_provisional_diagnosis ? tem_data.template_data.discharge_provisional_diagnosis : '',
        selectAdmissionProvisional: tem_data?.template_data?.provisional_diagnosis ? tem_data?.template_data?.provisional_diagnosis : '' 
      })
    }else{
      this.setState({
        postDisSumData: keys,
        icdADTblData: keys.admission_diagnosis ? keys.admission_diagnosis : [],
        typeAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.type_of_anaesthesia : '',
        nameAnaesthesia: keys.name_anaesthetist_and_type_of_anaesthesia ? keys.name_anaesthetist_and_type_of_anaesthesia.name_of_anaesthetist : '',
        icdDDTblData: keys.discharge_diagnosis ? keys.discharge_diagnosis : [],
        medicationTbl: keys.medication ? keys.medication : [],
        inHospmedicationTbl: keys.in_hospital_medication ? keys.in_hospital_medication : [],
        selectDiet: keys.discharge_instruction ? keys.discharge_instruction.diet : '',
        selectActivity: keys.discharge_instruction ? keys.discharge_instruction.acivity : '',
        adviceDischarge: keys.discharge_instruction ? keys.discharge_instruction.advice_discharge : '',
        invetigation: keys.investigation_and_test_report ? keys.investigation_and_test_report.investigation : '',
        selectDischargeProvisional: tem_data?.template_data?.discharge_provisional_diagnosis ? tem_data.template_data.discharge_provisional_diagnosis : '',
        selectAdmissionProvisional: tem_data?.template_data?.provisional_diagnosis ? tem_data?.template_data?.provisional_diagnosis : '' 
      })
    }
    })
  }

  ComPopClose() {
    this.setState({
      completePop: false
    })
  }
  finishSave() {
    this.postDischargeSummary(true)
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  render() {
    const { t, fromBillSummary } = this.props
    let attender = {
      lineOne: t("AttenderName"),
      lineTwo: `${this.state.attenderName ? this.state.attenderName : '-'}`,
      lineFour: t('ContactNumber'),
      LineFive: `${this.state.attenderMobile ? this.state.attenderMobile : '-'}`
    }
    let consultant = {
      lineOne: t("ConsultantName"),
      lineTwo: `${this.state.consultantName ? this.state.consultantName : '-'}`,
    }
    return (
      <Box component={'div'} className='eMed_patient_container'>
        <Box component={'div'} marginLeft={'0.5vw'} className='eMed_patient_header' sx={{ display: fromBillSummary ? "none" : "block" }}>
          <Box display={'flex'} alignItems='center'>
            <Box className='eMed_Patient_Details_Long eMed_patient_box'>
              <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
            </Box>
            <CommonPatientCard details={attender} showDetailed={true} />
            <ConsultantPatientCard name={consultant} />
          </Box>
        </Box>
        <Paper component={'div'} className={fromBillSummary ? 'eMed_Discharge_PaperBillView' : 'eMed_Discharge_PaperView'}>
          {/* function for discharge header values from this defined the discharge details fields */}
          {this.renderDischargeHeader()}

          {/* this function contains all the discharge details fields */}
          {this.renderDischargeDetails()}

          {/* footer */}
          <Box className='eMed_IP_Discharge_BtnGroup'>
            <Stack spacing={2} direction="row">
              <Button id='eMed_Config_btn' className='eMed_DisSum_Lay_select2' disabled={this.state.withOutAppointment?.is_ip} variant="outlined" onClick={() => { this.setState({clearPop:true}) }}>{t('Clear')}</Button>
              <Button id='eMed_Config_btn' className='eMed_DisSum_Lay_select2' disabled={((CheckAccessFunc("front_office", "In Patients", "Discharge Summary", null, "Tab")?.editAccess)) ? (this.state.selectLayout === '' || this.state.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip || this.state.selectLayout === undefined ? true : false || this.state.isSave) : true} onClick={() => {this.setState({isSave: true},()=>{this.saveTemplate(false)})}} variant="contained">{t('Save')}</Button>
              <Button id='eMed_Config_btn' className='eMed_DisSum_Lay_select2' disabled={((CheckAccessFunc("front_office", "In Patients", "Discharge Summary", null, "Tab")?.editAccess)) ? (this.state.selectLayout === '' || this.state.isFinish === true || fromBillSummary || this.state.withOutAppointment?.is_ip || this.state.selectLayout === undefined ? true : false) : true} onClick={() => { this.saveTemplate(true) }} variant="contained">{t('Finish')}</Button>
              <Button id='eMed_Config_btn' className='eMed_DisSum_Lay_select2' disabled={((this.state.disSumID || fromBillSummary || this.state.withOutAppointment?.is_ip) ? false : true) || this.state.isPrint} variant="contained" onClick={()=> {this.setState({isPrint: true},()=>{this.printDisSum()})}}>{t('Print')}</Button>
            </Stack>
          </Box>
        </Paper>
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.alertPop ?
          <ConfirmPopup
            cnfTitle={t('Confirmation')}
            cnfContent={t('Are you sure, Do you want to change the layout data loss may occur')}
            cnfAlertPopupClose={this.popClose.bind(this)}
            cnfData={this.confirmSave.bind(this)}
          />
          : null}
        {this.state.clearPop ?
          <ConfirmPopup
            cnfTitle={t('Confirmation')}
            cnfContent={t('Are you sure, Do you want to clear data loss may occur')}
            cnfAlertPopupClose={this.popClear.bind(this)}
            cnfData={this.clearTemplateData.bind(this)}
          />
          : null}
        {this.state.completePop ?
          <ConfirmPopup
            cnfTitle={t('Confirmation')}
            cnfContent={t('Please Confirm')}
            cnfContent1={t(`FRB`)}
            cnfAlertPopupClose={this.ComPopClose.bind(this)}
            cnfData={this.finishSave.bind(this)}
            showDateTimeError={!this.state.disDateTimePicker || !this.state.DischargeTime}
          />
          : null}
        {this.state.notifyPop ?
          < ConfirmPopup
            cnfTitle={t('Confirmation')}
            cnfContent={t('Are you sure, Do you want to change the template data loss may occur')}
            cnfAlertPopupClose={this.notiPopClose.bind(this)}
            cnfData={this.notiSave.bind(this)}
          />
          : null}

        {this.state.OpenPrevCopyPop ?
          < CopyDisSumPop
            CopyPopClose={this.CopyPopClose.bind(this)}
            PasteDisSumData={this.PasteDisSumData.bind(this)}
            disSumLayout={this.state.postDisSumData}
          />
          : null}
          {this.state.listenPopUp?
           <VoiceToText
          voiceTextConverter = {  this.voiceToTextgetter.bind(this)}
          listenPopUp={this.listenPopUp.bind(this)}
          />:null
          }
      </Box>
    )
  }
}
export default withTranslation()(DischargeSummary);