import { Autocomplete, Box, Button, Card, FormControl, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import CommonGridHeader, { CommonDeleteButton, CommonEditButton, CommonPatientCard, CommonPatientDetails, CommonPopUp, ConsultantPatientCard, DeletePopup } from '../../../../../Components/Common Components/CommonComponents'
import { withTranslation } from 'react-i18next'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import { getCachevalue, localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Colors } from '../../../../../Styles/Colors'
import './DoctorNotes.css';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CommonValidation from '../../../../../Components/CommonFunctions/CommonValidation';
import { DateTime } from 'luxon';
import Loader from '../../../../../Components/Loader';
import { CheckAccessFunc } from '../../../../../Components/CommonFunctions/CommonFunctions'


const TrackList = [ 
  {label:"IV", value :"IV"},
  {label:"IM", value :"IM"},
  {label:"Subcutaneous", value :"Subcutaneous"},
]

const IVSiteList = [ 
  {label:"Right Hand"},
  {label:"Left Hand"},
  {label:"Right Forearm"},
  {label:"Left Forearm"},
  {label:"Right Antecubital Fossa"},
  {label:"Left Antecubital Fossa"},
  {label:"Right Foot"},
  {label:"Left Foot"},
  {label:"Right Leg"},
  {label:"Left Leg"},
  {label:"Right Scalp"},
  {label:"Left Scalp"},
  {label:"Right Neck Veins"},
  {label:"Left Neck Veins"},
  {label:"Central Venous Line"},
  {label:"Internal Jugular"},
  {label:"Femoral"},
  {label:"Subclavian"},
  {label:"Gluteal"},
]

class InsulinTrack extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ipPatientData: {},
      InsulinData: [],
      Track: "IV",
      Reason: "",
      DrugCombination: "",
      Quantity: "",
      SkinSurface: "",
      Nurse: {
        "nurse": "",
        "staff_id": ""
      },
      NurseList: [],
      IVDateTime: new Date(),
      page: 0,
      pageSize: 10,
      SelectedList:{},
      isHistoryClicked: false,
      SelectedHistory: [],
      clinic_id: JSON.parse(localGetItem('SelectedDoctorClinicId')),
    }
  }

  GetNurseList = () => {
    try {
      let url = Serviceurls.FO_STAFF_LIST;
      if (this.props.is_From_IpNotes && this.state.clinic_id) {
        url = `${url}?clinic_id=${this.state.clinic_id}`
      }
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              NurseList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  GetIVTrackList = () => {
    let admission_id = this.state.ipPatientData?.id;
    let url = `${Serviceurls.IV_IM_TRACK}?ip_admission_id=${admission_id}`;
      if (this.props.is_From_IpNotes && this.state.clinic_id) {
        url = `${url}&clinic_id=${this.state.clinic_id}`
      }
    try {
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              InsulinData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    }, () => {
      setTimeout(() => {
        this.setState({ isErrorMsg: false })
      }, 2000);
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    }, () => {
      setTimeout(() => {
        this.setState({ successMsg: false })
      }, 1500);
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  LoaderFunction = (key = false) => {
    this.setState({
      isLoader: key
    })
  }

  componentDidMount() {
    let ipPatientData = getCachevalue("IppatientData")
    let patientData = JSON.parse(ipPatientData)
    this.setState({
      ipPatientData: patientData
    }, () => {
      this.GetNurseList()
      this.GetIVTrackList()
    })
  }

  onEditHandler = (params) => {
    try {
      this.state.Nurse.nurse = params?.row?.nurse === null ? "" : params?.row?.nurse
      this.state.Nurse.staff_id = params?.row?.nurse_id === null ? "" : params?.row?.nurse_id
      this.setState({
        SelectedList:params?.row,
        isEdit: true,
        Track: params?.row?.track,
        Reason: params?.row?.handling_reason,
        DrugCombination: params?.row?.drug_combination,
        Quantity: params?.row?.quantity_ml,
        SkinSurface: params?.row?.surface,
        Nurse: this.state.Nurse,
        IVDateTime:  params?.row?.iv_date+"T"+params?.row?.iv_time
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose = () => {
    this.setState({ 
      deletePopup: false,
      SelectedList:{}
     })
  }

  onDeleteHandler = (value) => {
      this.setState({
        deletePopup: true,
        SelectedList: value?.row
      })
  }

  GetHistoryData = (id) => {
    try {
      let url = Serviceurls.IP_CLINICNOTES_HISTORY + "?condition=ivimtrack" + "&row_id=" + id;
      if (this.state.clinic_id) {
          url = `${url}&clinic_id=${this.state.clinic_id}`
      }
      RestAPIService.getAll(url).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              SelectedHistory: response.data.data,
              isHistoryClicked: true,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  _getHistory(historydata) {
    historydata.forEach((element, index) => { element.sno = index + 1 })
    const { t } = this.props;
    let { anchorEl2 } = this.state;
    let open = Boolean(anchorEl2);

    const historyColumn = [
      { field: "sno", sortable: false, width: 50, headerName: t("SNo") },
      {
        field: "user", width: 150, headerName: t("User"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.user ? params?.row?.user : "-"}</Box>)
      },
      {
        field: "modified_date", width: 200, headerName: t("ModifiedDate"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.modified_date ? params?.row?.modified_date : "-"}</Box>)
      },
      {
        field: "modified_date_from", width: 200, headerName: t("Previous IV/IM Date & Time"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{(params?.row?.iv_date_from && params?.row?.iv_time_from) ? DateTime.fromJSDate(new Date(`${params?.row?.iv_date_from}T${params?.row?.iv_time_from}`)).toFormat("dd-MM-yyyy | hh:mm:ss a") : "-"}</Box>)
      },
      {
        field: "modified_date_to", width: 200, headerName: t("Changed IV/IM Date & Time"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{(params?.row?.iv_date_to && params?.row?.iv_time_to) ? DateTime.fromJSDate(new Date(`${params?.row?.iv_date_to}T${params?.row?.iv_time_to}`)).toFormat("dd-MM-yyyy | hh:mm:ss a"): "-"}</Box>)
      },
      {
        field: "track_from", width: 150, headerName: t("Previous Track"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.track_from ? params?.row?.track_from : "-"}</Box>)
      },
      {
        field: "track_to", width: 150, headerName: t("Changed Track"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.track_to ? params?.row?.track_to : "-"}</Box>)
      },
      {
        field: "handling_reason_from", width: 200, headerName: t("Previous Reason"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.handling_reason_from ?
            params?.row?.handling_reason_from?.length > 15 ? <Tooltip placement="top" title={params?.row?.handling_reason_from}><div>{params?.row?.handling_reason_from?.slice(0, 15) + '...'}</div></Tooltip>
              : params?.row?.handling_reason_from : '-'}</Box>)
      },
      {
        field: "handling_reason_to", width: 200, headerName: t("Changed Reason"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.handling_reason_to ?
            params?.row?.handling_reason_to?.length > 15 ? <Tooltip placement="top" title={params?.row?.handling_reason_to}><div>{params?.row?.handling_reason_to?.slice(0, 15) + '...'}</div></Tooltip>
              : params?.row?.handling_reason_to : '-'}</Box>)
      },
      {
        field: "drug_combination_from", width: 150, headerName: t("Previous Drug"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.drug_combination_from ?
            params?.row?.drug_combination_from?.length > 15 ? <Tooltip placement="top" title={params?.row?.drug_combination_from}><div>{params?.row?.drug_combination_from?.slice(0, 15) + '...'}</div></Tooltip>
              : params?.row?.drug_combination_from : '-'}</Box>)
      },
      {
        field: "drug_combination_to", width: 150, headerName: t("Changed Drug"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.drug_combination_to ?
            params?.row?.drug_combination_to?.length > 15 ? <Tooltip placement="top" title={params?.row?.drug_combination_to}><div>{params?.row?.drug_combination_to?.slice(0, 15) + '...'}</div></Tooltip>
              : params?.row?.drug_combination_to : '-'}</Box>)
      },
      {
        field: "quantity_ml_from", width: 150, headerName: t("Previous Quantity"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.quantity_ml_from ? params?.row?.quantity_ml_from : "-"}</Box>)
      },
      {
        field: "quantity_ml_to", width: 150, headerName: t("Changed Quantity"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.quantity_ml_to ? params?.row?.quantity_ml_to : "-"}</Box>)
      },
      {
        field: "surface_from", width: 200, headerName: t("Previous IV/IM Site"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.surface_from ? params?.row?.surface_from : "-"}</Box>)
      },
      {
        field: "surface_to", width: 200, headerName: t("Changed IV/IM Site"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.surface_to ? params?.row?.surface_to : "-"}</Box>)
      },
      {
        field: "nurse_name_from", width: 200, headerName: t("Previous Nurse"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.nurse_name_from ? params?.row?.nurse_name_from : "-"}</Box>)
      },
      {
        field: "nurse_name_to", width: 200, headerName: t("Changed Nurse"), headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.nurse_name_to ? params?.row?.nurse_name_to : "-"}</Box>)
      },
    ];
    return (
      <Box id="eMed_ipsrvConfig_accTbl">
        <DataGrid
          rows={historydata}
          columns={historyColumn}
          getRowId={(row) => row.sno}
          disableColumnMenu
          hideFooter
          components={{
            NoRowsOverlay: () => (
              <Stack className='eMed_conf_nodata'>
                {t("NoRecordsFound")}
              </Stack>
            )
          }}
          headerHeight={40}
          disableSelectionOnClick
        />
      </Box>
    )
  }

  MenuItem = (data) => {
    const { t } = this.props
    const multi_option = [
      { value: "History", label: t("History") },
    ]
    return (
      <div>
        <Tooltip title={t("More")} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option) => (
              <MenuItem key={option} onClick={() => { this.setState({ anchorEl: null }, () => { this.GetHistoryData(data.row.id) }) }}>
                {t(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  renderLeftContent = () => {
    this.state.InsulinData.forEach((element, index) => element.sno = index + 1);
    const { t } = this.props;
    const checkuserAccess = CheckAccessFunc("front_office", "In Patients","Clinical Notes", "IV / IM Track", "SubTab")?.editAccess || true;
    const myUser = JSON.parse(localGetItem("loggedInUserInfo"));
    const columns = [
      {
        field: "iv_datetime", headerName: "Date & Time", flex: 0.29, headerAlign: "center", align: "center",
        renderCell: (params) => {
          let startDate = (params?.row?.iv_date && params?.row?.iv_time) ? new Date(params?.row?.iv_date + "T" + params?.row?.iv_time) : ""
          let date = DateTime.fromJSDate(startDate).toFormat("dd-MM-yyyy hh:mm:ss a")
          return (
            <Box component={'div'}>{date ? date.length > 10 ?
              <Tooltip arrow title={date} placement='top'><div>{date.slice(0, 10) + '...'}</div></Tooltip> : date : "-"}</Box>
          )
        }
      },
      {
        field: "track", headerName: "Track", flex: 0.2, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.track ? params?.row?.track.length > 8 ?
          <Tooltip arrow title={params?.row?.track} placement='top'><div>{params?.row?.track.slice(0, 6) + '...'}</div></Tooltip> : params?.row?.track : "-"}</Box>)
      },
      {
        field: "handling_reason", headerName: "Reason", flex: 0.33,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.handling_reason ? params?.row?.handling_reason.length > 12 ?
            <Tooltip arrow placement='top' title={params?.row?.handling_reason}>
              <div>{params?.row?.handling_reason.slice(0, 10) + '...'}</div></Tooltip> : params?.row?.handling_reason : "-"}</Box>)
      },
      {
        field: "drug_combination", headerName: "Drug Combination", flex: 0.4,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.drug_combination ? params?.row?.drug_combination.length > 15 ?
            <Tooltip arrow placement='top' title={params?.row?.drug_combination}>
              <div>{params?.row?.drug_combination.slice(0, 12) + '...'}</div></Tooltip> : params?.row?.drug_combination : "-"}</Box>)
      },
      {
        field: "quantity_ml", headerName: "Quantity", flex: 0.2,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.quantity_ml ? params?.row?.quantity_ml.length > 10 ?
            <Tooltip arrow placement='top' title={params?.row?.quantity_ml}>
              <div>{params?.row?.quantity_ml.slice(0, 8) + '...'}</div></Tooltip> : params?.row?.quantity_ml : "-"}</Box>)
      },
      {
        field: "surface", headerName: "Site", flex: 0.35,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.surface ? params?.row?.surface.length > 12 ?
            <Tooltip arrow placement='top' title={params?.row?.surface}>
              <div>{params?.row?.surface.slice(0, 12) + '...'}</div></Tooltip> : params?.row?.surface : "-"}</Box>)
      },
      {
        field: "nurse", headerName: "Nurse", flex: 0.3,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.nurse ? params?.row?.nurse.length > 10 ?
            <Tooltip arrow placement='top' title={params?.row?.nurse}>
              <div>{params?.row?.nurse.slice(0, 8) + '...'}</div></Tooltip> : params?.row?.nurse : "-"}</Box>)
      },
      {
        field: "created_by", headerName: "Updated By", flex: 0.33,
        renderCell: (params) => (<Box component={'div'}>
          {params?.row?.created_by ? params?.row?.created_by.length > 12 ?
            <Tooltip arrow placement='top' title={params?.row?.created_by}>
              <div>{params?.row?.created_by.slice(0, 12) + '...'}</div></Tooltip> : params?.row?.created_by : "-"}</Box>)
      },
      {
        field: "action", headerName: "Action", flex: 0.3, headerAlign: "center", align: "center",
        renderCell: (params) => {
          const isDocCanEdit = this.props.is_From_IpNotes ? (myUser?.doctor_id === params?.row?.doctor_id) : checkuserAccess;
          return (<Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'center'} paddingRight={'0.5vw'}>
          <CommonEditButton disable={!isDocCanEdit} size='1.5vw' onClick={this.onEditHandler.bind(this, params)} />
          <CommonDeleteButton disable={!isDocCanEdit} size='1.5vw' onClick={this.onDeleteHandler.bind(this, params)} />
          {this.MenuItem(params)}
        </Box>)}
      }
    ]
    return (
      <Box component={'div'} className='eMed_insulin_left_card'>
        <DataGrid
          className='eMed_nur_notes_grid'
          rows={this.state.InsulinData}
          page={this.state.page}
          pageSize={this.state.pageSize}
          columns={columns}
          headerHeight={40}
          getRowId={(row) => row?.sno}
          disableSelectionOnClick
          localeText={{
            toolbarColumns: "",
            toolbarDensity: "",
            toolbarExport: "",
            toolbarFilters: "",
            toolbarExportPrint: ""
          }}
          initialState={{
            columns: {
                columnVisibilityModel: {
                  created_by: false
                }
            }
        }}
          components={{
            Toolbar: CommonGridHeader.CommonToolBar,
            NoRowsOverlay: () => {
              return (
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: "40vh" }}>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }
          }}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
        />
      </Box>
    )
  }

  historyPopUpClose() {
    this.setState({
      isHistoryClicked: false,
      SelectedHistory: []
    })
  }

  renderTextBox(label, Key, maxLength) {
    let states = this.state;
    return (
      <TextField
        className='eMed_small_textFields3'
        inputProps={{ maxLength: maxLength }}
        size='small'
        label={label}
        value={states[Key]}
        onChange={(e) => {
          let value = e.target.value;
          if (Key === "Quantity") {
            let isValid = CommonValidation.DecimalNumber(value)
            if ((isValid && value.length <= 6) || value == "") {
              states[Key] = value
            }
          }
          else {
            let isValid = CommonValidation.ALPHA_NUM_SPLCHAR(value)
            if (isValid || value == "") {
              states[Key] = value
            }
          }
          this.setState({ states })
        }}
      />
    )
  }


  renderDropdown(label, Key, List, LabelKey, ValueKey, disable = false) {
    let states = this.state;
    try {
      return (
        <FormControl size='small' className='eMed_small_textFields3'>
          <InputLabel>{label}</InputLabel>
          <Select
            disabled={disable}
            value={states[Key]}
            label={label}
            onChange={(e) => {
              states[Key] = e.target.value
              this.setState({ states }, () => {
                if (Key === "Block") {
                  this.renderFloorList()
                }
              })
            }}
            MenuProps={{
              style: { maxHeight: 300, maxWidth: 150 }
            }}
          >
            {
              List?.length > 0 ?
                List.map((list, index) => (
                  <MenuItem key={index} value={list[ValueKey]}>{list[LabelKey]}</MenuItem>
                )) : null
            }
          </Select>
        </FormControl>
      )
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  NurseSelection = () => {
    const { t } = this.props
    return (
      <Autocomplete
        className='eMed_small_textFields3'
        size='small'
        options={this.state.NurseList}
        getOptionLabel={(option) => option.nurse}
        value={this.state.Nurse}
        onChange={(event, newValue) => {
          this.setState({
            Nurse: newValue
          })
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("NameofTheNurse*")}
            placeholder={t("NameofTheNurse*")}
          />
        )}
      />
    )
  }

  ClearData() {
    this.setState({
      Track: "IV",
      Reason: "",
      DrugCombination: "",
      Quantity: "",
      SkinSurface: "",
      Nurse: {
        "nurse": "",
        "staff_id": ""
      },
      IVDateTime: new Date(),
      deletePopup: false,
      SelectedList:{},
      isEdit: true,
    })
  }

  handleDelete(){
    try{
      var states = this.state
      RestAPIService.delete( Serviceurls.IV_IM_TRACK + "?track_id=" + states.SelectedList?.id).
      then((response) => {
          if (response.data.status === "success") {
              this.successMessage(response.data.message)
              this.ClearData()
              this.GetIVTrackList()
          } else {
              this.errorMessage(response.data.message)
          }
      }).catch(error => {
          this.errorMessage(error.message)
      })
  }
  catch(e){
      this.errorMessage(e.message)
  }
  }


  validation() {
    try {
      let states = this.state
      var IV_DateTime = new Date(states.IVDateTime)
      var IVDate = DateTime.fromJSDate(IV_DateTime).toFormat('yyyy-MM-dd')
      var IVTime = DateTime.fromJSDate(IV_DateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      const myUser = JSON.parse(localGetItem("loggedInUserInfo"));
      if (states.Track !== "" &&
        CommonValidation.removeSpace(states.Reason) !== "" &&
        CommonValidation.removeSpace(states.DrugCombination) !== "" &&
        CommonValidation.removeSpace(states.Quantity) !== "" &&
        states.SkinSurface !== "" &&
        states.Nurse?.staff_id !== "" &&
        IVDate !== "Invalid DateTime" &&
        IVTime !== "Invalid DateTime" 
      ) {
        let data = {
          "ip_admission_id": states.ipPatientData?.id,
          "doctor_id": this.props.is_From_IpNotes ? myUser?.doctor_id : states?.ipPatientData?.doctor_id,
          "clinic_id": this.props.is_From_IpNotes ? this.state.clinic_id : undefined,
          "track": states.Track,
          "iv_date": IVDate,
          "iv_time": IVTime,
          "handling_reason": CommonValidation.removeSpace(states.Reason),
          "drug_combination": CommonValidation.removeSpace(states.DrugCombination),
          "quantity_ml": CommonValidation.removeSpace(states.Quantity),
          "surface": states.SkinSurface,
          "nurse_name_id": states.Nurse?.staff_id,
        }
        if(states.isEdit){data["track_id"] = states.SelectedList.id}
        this.LoaderFunction(true)
        RestAPIService.create(data, Serviceurls.IV_IM_TRACK).
          then((response) => {
            if (response.data.status === "success") {
              this.ClearData()
              this.GetIVTrackList()
              this.LoaderFunction(false)
              this.successMessage(response.data.message)
            } else {
              this.LoaderFunction(false)
              this.errorMessage(response.data.message)
            }
          }).catch(error => {
            if (error?.response?.data?.status === "fail") {
              this.LoaderFunction(false)
              this.errorMessage(error.response.data.message)
            } else {
              this.LoaderFunction(false)
              this.errorMessage(error.message)
            }
          })
      }
      else {
        if(CommonValidation.removeSpace(states.Reason) === "" && CommonValidation.removeSpace(states.DrugCombination) === "" && CommonValidation.removeSpace(states.Quantity) === "" && states.SkinSurface === ""  && states.Nurse?.staff_id === ""){
          this.errorMessage("Enter All Mandatory Fields")
        }
        else if (states.Track === "") { this.errorMessage("Select the Track") }
        else if (CommonValidation.removeSpace(states.Reason) === "") { this.errorMessage("Enter the Reason") }
        else if (CommonValidation.removeSpace(states.DrugCombination) === "") { this.errorMessage("Enter the Drug Combination") }
        else if (CommonValidation.removeSpace(states.Quantity) === "") { this.errorMessage("Enter the Quantity") }
        else if (states.SkinSurface === "") { this.errorMessage("Select the IV Site") }
        else if (states.Nurse?.staff_id === "") { this.errorMessage("Select the Nurse") }
        else if (IVDate == "Invalid DateTime"  || IVTime == "Invalid DateTime" ) { this.errorMessage("Invalid Date & Time") }
      }
    }
    catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRightContent = () => {
    const { t } = this.props
    const currentDate = new Date();
    const states = this.state;
    const admissionDate = states?.ipPatientData?.admitted_date
      ? new Date(states.ipPatientData?.admitted_date)
      : states?.ipPatientData?.admission_date
        ? new Date(states.ipPatientData?.admission_date)
        : currentDate;
    const dischargeDate = states.ipPatientData?.discharge_date && (new Date(states.ipPatientData?.discharge_date) <= currentDate) ?
      new Date(states.ipPatientData?.discharge_date) : currentDate;
    return (
      <Stack component={"div"}>
        <Paper>
          <Box component={'div'} height={'58vh'}>
            <Typography className='eMed_refdoc_title'>{t("IV/IMTrack")}</Typography>
            <Box component={'div'} sx={{ backgroundColor: Colors.ThemeLightColor, marginLeft: "1.5vw", marginBottom: '0.5vw' }} className='eMed_DocNotes_DateTime' >
              <Typography marginLeft={'0.5vw'} color='#616161' fontWeight={'600'}>{t("Date&Time*")}</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                 inputFormat='DD-MM-YYYY & hh:mm:ss A'
                  value={this.state.IVDateTime}
                  onChange={(newDate) => {
                    if (newDate === null) {
                      this.setState({ IVDateTime: new Date() })
                    }
                    else if (newDate > new Date()) {
                      this.setState({ IVDateTime: new Date() })
                    }
                    else {
                      this.setState({ IVDateTime: newDate })
                    }
                  }}
                  // minDate={this.props?.IPadmissionDetails?.admitted_date}
                  minDate={admissionDate}
                  maxDate={dischargeDate}
                  renderInput={(params) => <TextField size='small'
                    {...params}
                    fullWidth
                    variant='standard'
                    className='eMed_DocNotes_datePic_Textfield'
                  />}
                />
              </LocalizationProvider>
            </Box>
            <Box component={"div"} className="eMed_Row_Div">
              <Box>{this.renderDropdown(`${t("Track*")}`, "Track", TrackList, "label", "value")}</Box>
              <Box>{this.renderTextBox(`ReasonForHandling ${this.state.Track}Line/insulin*`, "Reason", 50)}</Box>
            </Box>
            <Box component={"div"} className="eMed_Row_Div">
              <Box>{this.renderTextBox(`${t("DrugCombination*")}`, "DrugCombination", 20)}</Box>
              <Box>{this.renderTextBox(`${t("QuantityinML*")}`, "Quantity", 15)}</Box>
            </Box>
            <Box component={"div"} className="eMed_Row_Div">
              <Box>{this.renderDropdown(`${this.state.Track} Site*`, "SkinSurface",IVSiteList,"label", "label")}</Box>
              <Box>{this.NurseSelection()}</Box>
            </Box>
          </Box>
          <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
            <Button variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
            <Button
            disabled={!CheckAccessFunc("front_office", "In Patients","Clinical Notes", "IV / IM Track", "SubTab")?.editAccess}
            variant='contained' size="small" id="eMed_Config_btn" onClick={() => { this.validation() }}>{t("Add")}</Button>
          </Stack>
        </Paper>
      </Stack>
    )
  }
  render() {
    const { t } = this.props
    var { ipPatientData } = this.state
    let attender = {
      lineOne: t("AttenderName"),
      lineTwo: `${ipPatientData.attender_name ? ipPatientData.attender_name : '-'}`,
      lineFour: t('ContactNumber'),
      LineFive: `${ipPatientData.attender_mobile ? ipPatientData.attender_mobile : '-'}`
    }
    let consultant = {
      lineOne: t("ConsultantName"),
      lineTwo: `${ipPatientData.doctor_name ? ipPatientData.doctor_name : '-'}`,
    }
    return (
      <Box sx={{backgroundColor:Colors.ComponentBgColor}}>
        <Box component={'div'} marginLeft={'0.5vw'} className='eMed_patient_header'>
          <Box display={'flex'} alignItems='center'>
            <Box className='eMed_Patient_Details_Long eMed_patient_box'>
              <CommonPatientDetails data={this.state.ipPatientData} showDetailed={true} />
            </Box>
            <CommonPatientCard details={attender} showDetailed={true} />
            <ConsultantPatientCard name={consultant} width={"20dvw"}/>
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'row'} marginTop={"0.5vw"}>
          <Card elevation={3} className='eMed_DocNotes_Left_Main'>
            {this.renderLeftContent()}
          </Card>
          <Paper elevation={3} className='eMed_DocNotes_Right_Main' sx={{ height: "63.8vh" }}>
            {this.renderRightContent()}
          </Paper>
        </Box>
        <Loader loaderOpen={this.state.isLoader} />
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
          {this.state.deletePopup ? <DeletePopup
          DeletTitle={`${t("deleteMsg")} ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.handleDelete.bind(this)}
        /> : null}
        {(this.state.isHistoryClicked) ?
            <CommonPopUp width={"75vw"}
              backgroundColor={Colors.white}
              popUpClose={this.historyPopUpClose.bind(this)}
              title={t("History")}
              component={this._getHistory.bind(this, this.state.SelectedHistory)} />
            : null
          }
      </Box>
    )
  }
}

export default withTranslation()(InsulinTrack)