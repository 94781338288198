import { Bucket_Name } from "../../Utility/Constants";
import { localGetItem } from "../../Utility/Services/CacheProviderService";
import moment from "moment";
import { DateTime } from 'luxon';
import { CurrencySymbol } from "../../Utility/Constants";
import { Box, Tooltip, Typography } from "@mui/material";

let AWS = require('aws-sdk');
let s3 = new AWS.S3({
    signatureVersion: 'v4',
    accessKeyId: "AKIAT7TG52UHOEIYAJH4",
    secretAccessKey: "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",
    region: "ap-south-1",
    params: { Bucket: Bucket_Name },

});

// Amount Format Change To Indian Standard Format (With comma) ---- Starts
const AmountFormat = (InputAmount) => {
    const formatpattern = CurrencySymbol === "$" ? /\B(?=(\d{3})+(?!\d))/g : /\B(?=(\d{2})+(?!\d))/g;
    let isNegativeAmount = null;
    const DecimalAmount = (Useramount) => {
        var amount = String(Useramount).split(".")[0];
        var AfterDecimal = String(Useramount).split(".")[1] || "00";
        AfterDecimal = AfterDecimal?.length === 1 ? AfterDecimal + "0" : AfterDecimal?.slice(0, 2);
        amount = amount.toString();
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        return `${CurrencySymbol} ` + (isNegativeAmount ? "- " : "") + otherNumbers.replace(formatpattern, ",") + lastThree + "." + AfterDecimal
    }
    const WholeAmount = (Useramount) => {
        var amount = Useramount;
        amount = amount.toString();
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        return `${CurrencySymbol} ` + (isNegativeAmount ? "- " : "") + otherNumbers.replace(formatpattern, ",") + lastThree + ".00"
    }
    if (typeof (InputAmount) == "string") {
        isNegativeAmount = InputAmount.includes("-")
        if (InputAmount.includes("-")) { InputAmount = InputAmount.slice(0, 0) + InputAmount.slice(0 + 1) }
        if (InputAmount.includes(".")) {
            return DecimalAmount(InputAmount)
        }
        else {
            return WholeAmount(InputAmount)
        }
    }
    else if (typeof (InputAmount) == "number") {
        isNegativeAmount = InputAmount < 0
        if (InputAmount < 0) {
            InputAmount = InputAmount * -1;
        }
        if (InputAmount % 1 != 0) {
            return DecimalAmount(InputAmount)
        }
        else if (InputAmount % 1 == 0) {
            return WholeAmount(InputAmount)
        }
    }
}
// Amount Format Change To Indian Standard Format (With comma) ---- ends

// Time Converter Change 24 hours formate into 12 hours formate ---- Starts
const Time_convert = (Time) => {
    var gettime = Time.split("T")[1]
    var splittime = gettime.split(".")[0]
    var slicetime = splittime.slice(0, 5)
    var time = slicetime.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [slicetime];

    if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? ' AM' : ' PM';
        time[0] = +time[0] % 12 || 12;
    } else {
        return "Wrong Time Formate"
    }
    return time
}
// Time Converter Change 24 hours formate into 12 hours formate ---- ends

const formatDate = (input) => {
    let Date = "-"
    if (input !== null && input !== undefined && input !== "") {
        let datePart = input.match(/\d+/g),
            year = datePart[0].substring(0),
            month = datePart[1], day = datePart[2];
        if (year.length == 4) {
            Date = day + '-' + month + '-' + year;
        } else {
            Date = year + '-' + month + '-' + day;
        }

    }
    return Date
}

const formatDateAndTime = (input) => {
    let date = input.split(" ")[0]
    let time = input.split(" ")[1].split(":")[0] + ":" + input.split(" ")[1].split(":")[1]
    let medium = input.split(" ")[2]
    let dateFormat = formatDate(date)
    let finalDateTime = dateFormat + " " + time + " " + medium

    return finalDateTime

}

const formatDateAndTimeMeth2 = (input) => {
    if (!input) return '';
    let date = input.split("T")[0]
    let time = input.split("T")[1].split(":")[0] + ":" + input.split("T")[1].split(":")[1]
    let dateFormat = formatDate(date)
    let timeFormat = timeOnlyConvert(time)
    let finalDateTime = dateFormat + " | " + timeFormat

    return finalDateTime
}

const CheckAccessFunc = (Module, MenuName, TabName = null, SubTabName = null, Type) => {
    try {
        let AcccessObject = {
            "viewAccess": true,
            "editAccess": true,
            "title": ""
        }
        let AccessData = JSON.parse(localGetItem("loggedInUserInfo"))?.data
        let displyName = JSON.parse(localGetItem("loggedInUserInfo"))?.display_name
        // let AccessData = MODULE_LIST_DATA
        if(Module === "front_office"){
            Module = JSON.parse(localGetItem("loggedInUserInfo"))?.display_name
        }
        if(Module === "Radiology"){
            Module = JSON.parse(localGetItem("loggedInUserInfo"))?.display_name
        }
        if(Module === "Pharmacy"){
            Module = JSON.parse(localGetItem("loggedInUserInfo"))?.pharmacy_name
        }
        if(Module === "Laboratory"){
            Module = JSON.parse(localGetItem("loggedInUserInfo"))?.laboratory_name
        }
        if (JSON.parse(localGetItem("loggedInUserInfo"))?.is_user === true) {  // Check Access only for User role
            let Data = []
            if (Type === 'Menu') {
                Data = AccessData.find(data => data['title'] ? data['title'] === Module : data['sub_name'] === displyName)
                Data = Data?.data?.find(data => data['title'] === MenuName)
                AcccessObject = Data
            }
            else if (Type === 'Tab') {
                Data = AccessData.find(data => data['title'] ? data['title'] === Module : data['sub_name'] === displyName)
                Data = Data?.data.find(data => data['title'] === MenuName)
                Data = Data?.main_tabs.find(data => data['title'] === TabName)
                AcccessObject = Data
            }
            else if (Type === 'SubTab') {
                Data = AccessData.find(data => data['title'] ? data['title'] === Module : data['sub_name'] === displyName)
                Data = Data?.data.find(data => data['title'] === MenuName)
                Data = Data?.main_tabs.find(data => data['title'] === TabName)
                Data = Data?.sub_tabs.find(data => data['title'] === SubTabName)
                AcccessObject = Data
            }
        }
        if (AcccessObject === undefined) {
            if (MenuName === "Settings") {
                return {
                    "viewAccess": false,
                    "editAccess": false,
                    "title": ""
                }
            } else {
                return {
                    "viewAccess": true,
                    "editAccess": true,
                    "title": ""
                }
            }
        }
        else {
            return AcccessObject
        }

    }
    catch (e) {
        return {
            "viewAccess": true,
            "editAccess": true,
            "title": ""
        }
    }

}

const splitDate = (value) => {
    let date = value?.split("T")[0]
    return formatDate(date)
}

const timeOnlyConvert = (time) => {
    const [hourString, minute] = time.split(":");
    const hour = +hourString % 24;
    const hours = hour?.length < 10 ? ('0' + hour) : hour
    return (hours % 12 || 12) + ":" + minute + (hours < 12 ? " AM" : " PM");
}

const DifferntTimeConverter = (timeString) => {
    var dateTime = new Date(timeString);
    var formattedTime = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return (formattedTime);
}

const CalculateAgeFromDob = (date) => {
    // let age = "";
    // let dob = DateTime.fromJSDate(date);
    // var year_age = DateTime.now().diff(dob, 'years');
    // age = Math.floor(year_age.years) || 0;
    // return (date < new Date() && date > new Date("1872-01-01")) ? age : "";
    let age = "";
    let dob = new Date(date);
    let m = moment(dob, "DD-MM-YYYY");
    var end_date = moment();

    var year_age = end_date.diff(m, 'year') || 0;
    // reset(year) year with difference year
    m = moment(m).add(year_age, 'years');

    var month_age = end_date.diff(m, 'month') || 0;
    // reset(month) month with difference month
    m = moment(m).add(month_age, 'months');

    var day_age = end_date.diff(m, 'days') || 0;

    if (year_age > 0) {
        age = year_age || 0;
    } else {
        age = year_age + "Y/" + month_age + "M/" + day_age + "D";
    }
    return (date < new Date() && date > new Date("1872-01-01")) ? age : "";
}

const CalculateApproxDOB = ageEntered => {

    var year = 0;
    var month = 0;
    var days = 0;
    var tempDate;
    year = +(ageEntered)
    if (!year) {
        tempDate = null;
    } else {
        tempDate = DateTime.now().minus({ years: year }).toBSON();
    }

    return tempDate;
}

// AWS STORAGE LIMIT FOR CLINIC

const getClinicS3Storage = async (clinic_id) => {
    let totalSize = 0,
        ContinuationToken;
    do {
        let resp = await s3
            .listObjectsV2({
                Bucket: Bucket_Name,
                Prefix: `${Bucket_Name}/${clinic_id}/`, ContinuationToken,
            })
            .promise()
            .catch((e) => console.log(e));
        resp.Contents.forEach((o) => (totalSize += o.Size));
        ContinuationToken = resp.NextContinuationToken;
    } while (ContinuationToken);
    let bytes = totalSize;
    let decimals = 2;
    let bucketSize = 0;
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    bucketSize = `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]
        }`;
    return totalSize;
}

const ConvertTimeToDate = (formatedTime) => {
    const currentDate = new Date();

    const [time, amPm] = formatedTime.split(' ');
    const [hours, minutes] = time.split(':');

    const hoursNumeric = parseInt(hours);
    const minutesNumeric = parseInt(minutes);

    currentDate.setHours(hoursNumeric, minutesNumeric);

    // If the time is in PM, add 12 hours to the hoursNumeric
    if (amPm === "PM" && hoursNumeric !== 12) {
        currentDate.setHours(hoursNumeric + 12);
    }
    return currentDate;
}

const calculateBMI = (weight, height) => {
    // Convert height from centimeters to meters
    height = +height / 100;

    // Calculate BMI
    const bmi = +weight / ((+height) * (+height));

    return bmi;
}

const FindCreditType = Data => {
    let CreditType = "Patient credit"
    for (let i = 0; i <= Data?.length; i++) {
        if (Data[i]?.credit_type) {
            CreditType = Data[i]?.credit_type
            break
        }
    }
    return CreditType;
}

const ProfitLossPercent = (original, compare) => {
    let Value = 0
    let originalValue = original ? original : 0
    let CompareValue = compare ? compare : 0
    Value = (originalValue - CompareValue)
    return Math.round(Value)

}

const getLabS3Storage = async (Lab_ID) => {
    let totalSize = 0,
        ContinuationToken;
    do {
        let resp = await s3
            .listObjectsV2({
                Bucket: Bucket_Name,
                Prefix: `${Bucket_Name}/` +
                    Lab_ID +
                    "/",
                ContinuationToken,
            })
            .promise()
            .catch((e) => console.log(e));
        resp?.Contents?.forEach((o) => (totalSize += o.Size));
        ContinuationToken = resp?.NextContinuationToken;
    } while (ContinuationToken);
    let bytes = totalSize;
    let decimals = 2;
    let bucketSize = 0;
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    bucketSize = `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]
        }`;
    return totalSize;
}

const formatToCustomDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

const convertDateTime = (inputDateTime) => {
    //  Example usage:
    //  Input "2023-12-27T14:07:11"
    //  Output '27-12-2023 | 2:07 PM';
    const date = new Date(inputDateTime);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${day}-${month}-${year}`;

    let amOrPm = 'AM';
    let formattedHours = parseInt(hours);

    if (formattedHours >= 12) {
        amOrPm = 'PM';
        if (formattedHours > 12) {
            formattedHours -= 12;
        }
    }

    const finalDateTime = `${formattedDate} | ${formattedHours}:${minutes} ${amOrPm}`;
    return finalDateTime;
}

const convertDateSlashToHyphen = (date) => {
    let [day, month, year] = date?.split('/');
    return `${year}-${month}-${day}`;
}

const reformatDateTime = (dateStr) => {
    if (!dateStr) return '';
    // Split the date and time parts
    let [datePart, timePart] = dateStr.split(' ');

    // Reformat the date part (YYYY-MM-DD to DD-MM-YYYY)
    let [year, month, day] = datePart.split('-');
    let formattedDate = [day, month, year].join('-');

    // Combine the formatted date with the time part
    return `${formattedDate} ${timePart}`;
}

const areValuesNotEmptyExceptKeys = (obj, keysToExclude) => {
    for (let key in obj) {
        if (!keysToExclude.includes(key) &&
            obj[key] !== null &&
            obj[key] !== undefined &&
            obj[key] !== false &&
            obj[key] !== 0 &&
            obj[key] !== '' &&
            !(Array.isArray(obj[key]) && obj[key].length === 0) && // Check for empty array
            !(typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) // Check for empty object
        ) {
            return true; // If a non-empty value is found for a key other than the excluded keys
        }
    }
    return false; // Return false if all values are empty or excluded
};

const calculateWeeksAndDays = (fromDate, toDate) => {
    const msInDay = 24 * 60 * 60 * 1000;
    const diffInMs = toDate - fromDate;
    const totalDays = Math.floor(diffInMs / msInDay);
    const weeks = Math.floor(totalDays / 7);
    const days = totalDays % 7;
    return { weeks, days };
};

//   // Example usage:
//   const inputDateTime = '2023-12-27T09:46:21';
//   const convertedDateTime = convertDateTime(inputDateTime);
//   console.log(convertedDateTime);

const ContentInTooltip = (text = '', maxLength = 7, fontSize = "1vw", fontWeight = 500) => {
    return <Box component={'div'}>
        {text ? text?.length > maxLength ?
            <Tooltip placement='top' title={text} arrow>
                <Typography sx={{ fontSize: fontSize, fontWeight : fontWeight }}>{text.slice(0, (maxLength - 2)) + "..."}</Typography></Tooltip> :
            <Typography sx={{ fontSize: fontSize, fontWeight : fontWeight }}>{text}</Typography> : "-"}</Box>
}

const OpenDirectPrint = (response) => {
    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);

    const container = document.createElement('div');
    const shadowRoot = container.attachShadow({ mode: 'closed' });

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none'; 
    iframe.src = fileURL; 

    shadowRoot.appendChild(iframe);
    document.body.appendChild(container);

    iframe.onload = function () {
        try {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();

        } catch (error) {
            console.log("Print error:", error);
        }
        
    }
}

const debounce = (callback, delay = 500) => {
    let timeoutId;
    return function (value) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => callback(value), delay);
    };
}


export {
    AmountFormat, Time_convert, formatDate, formatDateAndTime, CheckAccessFunc, timeOnlyConvert,
    CalculateAgeFromDob, CalculateApproxDOB, DifferntTimeConverter, splitDate, ProfitLossPercent,
    FindCreditType, calculateBMI, getClinicS3Storage, ConvertTimeToDate, formatToCustomDateTime,
    getLabS3Storage, convertDateTime, formatDateAndTimeMeth2, reformatDateTime, areValuesNotEmptyExceptKeys,
    calculateWeeksAndDays, convertDateSlashToHyphen, ContentInTooltip, OpenDirectPrint, debounce
}
